import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ALL_CATEGORIES } from "~/consts/data/categories";
import CategoryButton from "../CategoryButton";
import {useSelector} from "react-redux";
import {selectLang} from "~/store/slices/user";

export type CategorySelectorProps = {
  closeModal?: () => void;
  selectedCategory?: typeof ALL_CATEGORIES[number];
};

const CategorySelector: FC<CategorySelectorProps> = ({
  closeModal,
  selectedCategory,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const lang = useSelector(selectLang);

  const redirect = (path: string) => {
    closeModal && closeModal();
    history.push(`/${lang}${path}`);
  };

  return (
    <div>
      {ALL_CATEGORIES.map((categoryName, key) => (
        <CategoryButton
          key={`filter-category-buttom-${categoryName}-${key}`}
          variant={categoryName}
          className={`filter__category-button ${
            selectedCategory === categoryName
              ? "filter__category-button--selected"
              : ""
          } ${categoryName}`}
          onClick={() => {
            redirect(`/products/${categoryName}`);
          }}
        />
      ))}
    </div>
  );
};

export default CategorySelector;
