import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Category, City, SubCategory, VehicleBrand, VehicleModel } from "~/api";
import {
  selectCities,
  selectSubCategoriesByParent,
} from "~/store/slices/categories";
import {
  selectVehicleBrands,
  selectVehicleModels,
} from "~/store/slices/vehicles";
import FilterForm, { numberField, radioField, RadioItem } from "./FilterForm";
import Button from "~/components/Button";
import {
  VehicleFuel,
  VehicleGearbox,
  VEHICLE_FUELS,
  VEHICLE_GEARBOXES,
} from "~/consts/data/vehicles";
import Colors from "~/consts/Colors";

export type VehicleFilterFields = {
  category_id: string,
  vehicle_brand?: VehicleBrand;
  vehicle_model?: VehicleModel;
  city?: City;
  sub_category?: SubCategory;
  price_min?: number;
  price_max?: number;
  vehicle_year_min?: number;
  vehicle_year_max?: number;
  vehicle_milage_min?: number;
  vehicle_milage_max?: number;
  vehicle_gearbox?: VehicleGearbox;
  vehicle_fuel?: VehicleFuel;
};

export type VehicleFilterProps = {
  category: Category;
  filterValues?: VehicleFilterFields;
  setFilterValues: React.Dispatch<React.SetStateAction<any>>;
  onSubmit: (values: VehicleFilterFields) => void;
};

const VehicleFilter: FC<VehicleFilterProps> = ({
  category,
  filterValues,
  setFilterValues,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [_filterValues, _setFilterValues] = useState(filterValues);
  const vehicleBrands = useSelector(selectVehicleBrands);
  const vehicleModels = useSelector(selectVehicleModels);
  const cities = useSelector(selectCities);
  const subCategories = useSelector(selectSubCategoriesByParent(category.id));
  const filteredModels = vehicleModels.filter(
    (vehicle_model) =>
      vehicle_model?.parentId === _filterValues?.vehicle_brand?.id
  );
  const vehicleFuels: RadioItem[] = VEHICLE_FUELS.map((fuelName) => ({
    id: fuelName,
    name: fuelName,
  }));
  const vehicleGearboxes: RadioItem[] = VEHICLE_GEARBOXES.map(
    (gearboxName) => ({
      id: gearboxName,
      name: gearboxName,
    })
  );

  const vehicleFilters = [
    radioField("city", t("FILTERS.city"), cities),
    numberField("price_min", t("FILTERS.price_min")),
    numberField("price_max", t("FILTERS.price_max")),
    radioField(
      "vehicle_brand",
      t("FILTERS.vehicle_brand_title"),
      vehicleBrands
    ),
    radioField(
      "vehicle_model",
      t("FILTERS.vehicle_model_title"),
      filteredModels,
      "vehicle_brand"
    ),

    radioField("sub_category", t("FILTERS.sub_category"), subCategories),
    numberField("vehicle_milage_min", t("FILTERS.vehicle_milage_min_title")),
    numberField("vehicle_milage_max", t("FILTERS.vehicle_milage_max_title")),
    numberField("vehicle_year_min", t("FILTERS.vehicle_year_min_title")),
    numberField("vehicle_year_max", t("FILTERS.vehicle_year_max_title")),

    radioField("vehicle_fuel", t("ADS_PAGE.VEHICLE.FUEL"), vehicleFuels),
    radioField(
      "vehicle_gearbox",
      t("ADS_PAGE.VEHICLE.GEARBOX"),
      vehicleGearboxes
    ),
  ];
  let initialValues = {};
  vehicleFilters.map((filter) => {
    initialValues = {
      ...initialValues,
      [filter.name]: "",
    };
  });

  const handleSetFilterValues = (values: any) => {
    console.log('Reset filter ....1 ', values)
    _setFilterValues(values);
    setFilterValues(values);
  };

  const filters = vehicleFilters;
  return (
    <Formik
      initialValues={_filterValues || { ...initialValues }}
      onSubmit={onSubmit}
    >
      {(formik) => {
        console.log('initialValues: ', initialValues)
        console.log('_filterValues: ', _filterValues)
        return (
          <form onSubmit={formik.handleSubmit} className="filter_form">
            <>
              <FilterForm
                formik={formik}
                setFilterValues={handleSetFilterValues}
                filters={filters}
                category={"vehicle"}
              />
              <div className="filter__buttons-wrapper">
                <Button
                  type="submit"
                  className="filter__submit-button"
                  styles={{ backgroundColor: Colors.PURPLE }}
                >
                  {t("BUTTONS.APPLY")}
                </Button>
                <Button
                  className="filter__reset-button"
                  onClick={() => {
                    console.log('formik:: ', formik)
                    formik.resetForm({values: initialValues});
                    handleSetFilterValues(null);
                  }}
                >
                  {t("BUTTONS.CLEAR")}
                </Button>
              </div>
            </>
          </form>
        );
      }}
    </Formik>
  );
};
export default VehicleFilter;
