import React, { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { LoginModel } from "~/api";
import LoginForm from "~/components/LoginForm";
import { useDispatch } from "~/store";
import {loginUser, selectLang, setProfileType, setSocialLogin} from "~/store/slices/user";
import "./styles.scss";
import { addErrorMessage } from "~/store/slices/snackbars";
import useNotEnglish from "~/hooks/useNotEnglish";
import {useSelector} from "react-redux";

const LoginPage: VFC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const notEnglish = useNotEnglish();
  const lang = useSelector(selectLang);

  const handleLogin = (credentials: LoginModel) => {
    setLoading(true);
    dispatch(loginUser(credentials))
      .unwrap()
      .then((response) => {
        if (response.status === "ok") {
          dispatch(setSocialLogin(false));
          dispatch(setProfileType(response.user_type));
        } else {
          dispatch(
            addErrorMessage({
              message: t("FACEBOOK.LOGIN_FAILED"),
              title: response.message,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        history.push(`/${lang}`);
      });
  };

  return (
    <main className="login-page">
      <div className="login-page__form">
        <LoginForm onSubmit={handleLogin} loading={loading} />
      </div>
    </main>
  );
};
export default LoginPage;
