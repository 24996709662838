import React from "react";
import COLORS from "../consts/Colors";

import OtherWhiteSvg from "~/assets/icons/other-white.svg";
import AccommodationWhiteSvg from "~/assets/icons/accommodation-white.svg";
import ElectronicsWhiteSvg from "~/assets/icons/electronics-white.svg";
import VehicleWhiteSvg from "~/assets/icons/vehicles-white.svg";
import VehiclesCategorySvg from "~/assets/icons/vehicles-category.svg";
import ElectronicsCategorySvg from "~/assets/icons/electronics-category.svg";
import AccommodationCategorySvg from "~/assets/icons/accommodation-category.svg";
import OtherCategorySvg from "~/assets/icons/other-category.svg";

const renderCategory = (name?: string)  => {
  switch (name) {
    case "vehicles":
      return {
        categoryColor: COLORS.PURPLE,
        color: COLORS.PURPLE,
        icon: <VehicleWhiteSvg />,
        mobIcon: <VehiclesCategorySvg  width={37} height={25} viewBox="0 0 50 30"/>
      };
    case "accommodations":
      return {
        categoryColor: COLORS.GREEN,
        color: COLORS.PURPLE,
        icon: <AccommodationWhiteSvg />,
        mobIcon: <AccommodationCategorySvg width={40} height={32} viewBox="0 0 50 30" />
      };
    case "electronics":
      return {
        categoryColor: COLORS.DARK_SILVER,
        color: COLORS.PURPLE,
        icon: <ElectronicsWhiteSvg />,
        mobIcon: <ElectronicsCategorySvg width={37} height={25} viewBox="0 0 50 30" />
      };
    case "other":
      return {
        categoryColor: COLORS.DARK_SILVER,
        color: COLORS.PURPLE,
        icon: <OtherWhiteSvg />,
        mobIcon: <OtherCategorySvg width={40} height={32} viewBox="0 0 50 30" />

      };
    default:
      return {
        categoryColor: COLORS.PINK,
        color: COLORS.PURPLE,
      };
  }
};

export default renderCategory;
