import React, { ReactNode, useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchProductDetails, Media } from "~/api";

import { Categories } from "~/consts/data/categories";
import { getCurrentCategory } from "~/helpers";
import renderCategory from "~/helpers/renderCategory";
import { selectCategories } from "~/store/slices/categories";
import LikeIcon from "../../assets/icons/like-icon.svg";
import RedLikeIcon from "../../assets/icons/like-red-icon.svg";

import ProductCarousel from "../ProductCarousel";
import "./styles.scss";
import { useDevice } from "~/hooks/useDevice";
import { selectLang } from "~/store/slices/user";
import ResponsiveImage from '~/components/ResponsiveImage';
import Button from '~/components/Button';
import { t } from 'i18next';

export type ProductCardProps = {
  logo: string;
  id: number;
  title: string;
  description: string;
  location: string;
  moreAds?: boolean;
  date: string;
  priceTag: string;
  className?: string;
  categorySlug?: string;
  media?: Media[];
  icon?: ReactNode;
  saved_ad?: boolean;
  user_id: number;
  handleDelete?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => void;
  status:string;
};
const ProductCard: VFC<ProductCardProps> = ({
                                              logo,
                                              id,
                                              title,
                                              description,
                                              location,
                                              date,
                                              priceTag,
                                              categorySlug = "all",
                                              className = "",
                                              media,
                                              icon, saved_ad,
                                              moreAds,
                                              handleDelete,
  status

                                            }) => {
  const categories = useSelector(selectCategories);
  const {category: _categoryName} = useParams<{ category?: Categories }>();
  const categoryName = !_categoryName ? "all" : _categoryName;
  const currentCategory = getCurrentCategory(categories, categoryName);
  const device: string = useDevice();
  const history = useHistory();
  const lang = useSelector(selectLang);

  return (
    <div
      className={ `product-card product-status-${status} ${ className }` }
      onClick={ () =>
        history.push({
          pathname: `/${ lang }/products/${ categorySlug }/${ id }`,
          state: {logo},
        })
      }
    >
      { currentCategory.name === "Accommodation" ? (
        <div style={ {position: "relative"} }>
          <ProductCarousel
            mobileStyle={ !moreAds ? {height: 128, width: 128} : {} }
            desktopStyle={ {height: 320, width: 480} }
            accomodationMark={ false }
            showNavigation={ device !== "isMobile" }
            media={ media?.map((item) => {
              return item.thumbnail_url;
            }) }
          />
          { logo && (
            <div className="product-card__logo-accomodationCard">
              <img className="product-card__logoImg" src={ logo }/>
            </div>
          ) }
        </div>
      ) : (
        <div className="product-card__image-wrapper">
          { !!media.length &&
           <ResponsiveImage
            className={ 'product-card__image' }
            fullImageUrl={ media[0]?.url ? media[0]?.url : media[0]?.thumbnail_url }
            alt={ title }
            title={ title }
            thumbnailImageUrl={ media[0]?.thumbnail_url }
            layout={ 'list' }
           />
          }

          { logo && (
            <div className="product-card__logo-mainCard">
              <img className="product-card__logoImg" src={ logo }/>
            </div>
          ) }
        </div>
      ) }
      { moreAds && <h3 className="product-card__title">{ title }</h3> }
      <div
        className={
          currentCategory.name === "Accommodation"
            ? "product-card__acomodation-content"
            : "product-card__content"
        }
      >
        <div className="product-card__product-information">
          <div className="product-card__main-information">
            { !moreAds && <h3 className="product-card__title">{ title }</h3> }
            <div className="product-card__description">
              <p className="product-card__location">{ location }</p>
              <p className="product-card__description">{ description }</p>
            </div>
          </div>
          <div className={ "product-card__price_block" }>
            <p
              className={ `${ "product-card__price" } ${
                currentCategory.name === "Accommodation" &&
                device !== "isMobile"
                  ? "product-card__accomodationPrice"
                  : ""
              }` }
              style={ {
                color:
                  currentCategory.name === "Accommodation" &&
                  device !== "isMobile"
                    ? "white"
                    : renderCategory(currentCategory.slug).categoryColor,
              } }
            >
              { priceTag + " $" }
            </p>
            { device === "isMobile" && !moreAds && (
              <div className="product-card__date">{ date }</div>
            ) }
          </div>
        </div>
        { (device === "isTablet" || device === "isDesktop" || moreAds) && (
          <div className="product-card__data-like-section">
            <div className="product-card__date">{ date }</div>
            { icon ? (
              <button
                onClick={ (e) => handleDelete(e, id) }
                className="product-card__icon"
              >
                { icon }
              </button>
            ) : saved_ad ? (
              <RedLikeIcon className="product-card__like-icon"/>
            ) : (
              <LikeIcon className="product-card__like-icon"/>
            ) }
          </div>
        ) }
      </div>
      {status==='pending' &&
       <>
         <Button loading={false} disabled={true} className={"button--pending"}>
           {t('PRODUCT.PENDING')}
         </Button>
       </>
      }
    </div>
  );
};
export default ProductCard;
