import api, { ResponseWrapper } from "./axios";

export type VehicleBrand = {
  id: number;
  name: string;
};
export type VehicleModel = {
  parentId?: number;
  id: number;
  name: string;
};

export const fetchVehicleBrands = (token: string) =>
  api.get<ResponseWrapper<VehicleBrand[]>>(`/vehicle-brands`, {params: {token}});

export const fetchVehicleModels = (id: number, token: string) =>
  api.get<ResponseWrapper<VehicleModel[]>>(`/vehicle-models/${id}`, {params: {token}});
