import {
  faCheck,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqual, snakeCase } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSnackbars,
  Snackbar as ISnackbar,
  removeMessage,
} from "../../store/slices/snackbars";
import "./styles.scss";

export type SnackbarProps = {
  snackbarTime?: number; // time until message will dissapear (ms)
};

const Snackbar = ({ snackbarTime = 5000 }) => {
  const dispatch = useDispatch();

  const [currentSnackbar, setCurrentSnackbar] = useState(null);

  const removeSnackbarMessage = (id: string) => {
    return setTimeout(() => {
      dispatch(removeMessage(id));
    }, snackbarTime);
  };

  const closeCurrentSnackbar = () => {
    currentSnackbar && dispatch(removeMessage(currentSnackbar.id));
  };

  const snackbars: ISnackbar[] = useSelector(selectSnackbars);
  useEffect(() => {
    if (!isEqual(currentSnackbar, snackbars[0])) {
      setCurrentSnackbar(null); //rendering empty component to show animation
      snackbars[0] && removeSnackbarMessage(snackbars[0].id);
      setTimeout(() => {
        setCurrentSnackbar(snackbars[0]);
      }, 0);
    }
  }, [snackbars]);

  if (!currentSnackbar) return <></>;

  return (
    <div className="snackbar">
      <div
        className={`snackbar__badge snackbar__badge--${currentSnackbar.type}`}
      >
        {currentSnackbar.type === "error" ? (
          <FontAwesomeIcon icon={faTimes} />
        ) : (
          <FontAwesomeIcon icon={faCheck} />
        )}
      </div>
      <h2 className="snackbar__title">{currentSnackbar.title}</h2>
      <p className="snackbar__message">{currentSnackbar.message}</p>
      <button
        className="snackbar__close-button"
        type="button"
        onClick={closeCurrentSnackbar}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
};

export default Snackbar;
