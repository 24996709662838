import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Category, ProductDetailsType } from "~/api";
import ProductCarousel from "~/components/ProductCarousel";
import ProductDetails from "~/components/ProductDetails";
import { CreateAdFields } from "../../components/CreateAdForm/types";
import useNotEnglish from "~/hooks/useNotEnglish";

export type ProductDetailsProps = {
  product: ProductDetailsType;
  category: Category;
  previewMark?: boolean;
  onSubmit?: (values: CreateAdFields) => void;
  onClick?: () => void;
  submitLoading?: boolean;
};

const ProductDetailsBlock: VFC<ProductDetailsProps> = ({
  product,
  category,
  previewMark,
  onSubmit,
  submitLoading,
}) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const notEnglish = useNotEnglish();

  const slideLogo = state?.logo;
  return (
    <>
      <h1 className="product-details-page__title">{product?.title} { product.status==="pending" ? `(${t('BUTTONS.PENDING')})` : '' }</h1>
      <div className="product-details-page__carousel">
        <ProductCarousel media={product?.media} youtube={product?.youtube} />

        {!!slideLogo && (
          <div
            className="product-card__logo-accomodationCard"
            style={{ borderRadius: "0 0 6px 0", width: "30%" }}
          >
            <img className="product-card__logoImg" src={slideLogo} />
          </div>
        )}
      </div>
      <div className="product-details-page__product-details">
        <ProductDetails
          previewMark={previewMark}
          product={product}
          category={category}
          onSubmit={onSubmit}
          submitLoading={submitLoading}
        />
      </div>
      <div className="product-details-page__description">
        <h2 className="product-details-page__description-title">
          {t("ADS_PAGE.DESCRIPTION_BY_SELLER")}
        </h2>
        <p className="product-details-page__description-text">
          {product?.description}
        </p>
      </div>
      <div className="product-details-page__ad-view">
        <span className="product-details-page__ad-view-label">
          {t("ADS_PAGE.VIEWS")}
        </span>
        <span className="product-details-page__ad-view-count">
          {product?.ad_views}
        </span>
      </div>
    </>
  );
};

export default ProductDetailsBlock;
