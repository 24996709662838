import React, { useState } from "react";
import "./styles.scss";
import i18n from "../../i18n/config";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {useDevice} from "../../hooks/useDevice";
import { changeLang } from "../../store/slices/user/actions";
import { selectLang } from "../../store/slices/user/selectors";
import CheckedIcon from "../../assets/icons/checked-icon.svg"
import Eng from "../../assets/images/languages/en-language.png";
import Kur from "../../assets/images/languages/kur-language.png";
import Arb from "../../assets/images/languages/arab-language.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck
} from "@fortawesome/free-solid-svg-icons";
import LanguageScreen from "./LanguageScreen";
import {Redirect, useHistory, useLocation, useParams} from "react-router-dom";

interface LanguageProps {
  width?: number | false;
  height?: number | false;
}

const LanguageSwitcher = (): JSX.Element => {
  const device: string = useDevice();
  const dispatch = useDispatch();
  const lang = useSelector(selectLang);
  const { t } = useTranslation();
  const [checkIcon, setCheckIcon] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const toggling = () => setShowDropdown(!showDropdown);
  const os = useParams();
  const location = useLocation();
  const history = useHistory();

  const languageItems = [
    { label: t("common.Eng"), value: "en", img: Eng },
    { label: t("common.Arb"), value: "arab", img: Arb },
    { label: t("common.Kur"), value: "kur", img: Kur },
  ];

  const changeLanguage = async (lan: string) => {
    i18n.changeLanguage(lan);
    dispatch(changeLang(lan));
    localStorage.setItem("lang", lan);
    setShowDropdown(false);
    setCheckIcon(true)

    // I18nManager.forceRTL(lan === "arab" || lan === "kur");
    // RNRestart.Restart();
    console.log('RD ...')
    history.replace(`/${lan}`)
  };

  const getLabel = () => {
    return languageItems.filter(item => item.value === lang)?.[0]?.label || t("common.Eng")
  }

  return (
    <>
      {device === 'isTablet' || device === 'isMobile' ? (
        <LanguageScreen />
      ) :
      <div onClick={toggling} className="nav-item">
        <img src={lang === "en" || lang === null ? Eng : lang === "kur" ? Kur : Arb} className="drop-down__flag"/>
        <span className="drop-down__title">{getLabel()}</span>
        {showDropdown && (
          <div className="drop-down">
            <ul className="drop-down__list">
              {languageItems.map((lan, index) => (
                <li onClick={() => changeLanguage(lan.value)} key={index} className={"drop-down__listItem"}>
                    {lang === lan.value ? (
                      <div>
                        <CheckedIcon className="drop-down__checkIcon"/>
                      </div>
                    ) : <CheckedIcon className="drop-down__checkIcon-inactive"/> }
                    <div>
                      <img
                        className="drop-down__flag"
                        src={lan.img}
                        alt="Language Flag"
                      />
                    </div>
                    <div>
                      <p className="drop-down__label">
                        {lan?.label || t("common.Eng")}
                      </p>
                    </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      }
    </>
  );
};

export default LanguageSwitcher;
