import moment from "moment";
import React, { ReactNode, useEffect, useState, VFC } from "react";
import { fetchFiltersList, Product } from "~/api/product";
import { useDispatch, useSelector } from "react-redux";
import { selectUserToken } from "~/store/slices/user/selectors";
import { fetchSavedFilters } from "~/store/slices/products/actions";
import WatchlistCard from "./WatchlistCard";
import i18n from "~/i18n/config";

export type ProductsProps = {
  products: Product[];
  icon?: ReactNode;
  setListResult: (x: boolean) => void;
  handleDelete?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => void;
};

const Watchlist: VFC<ProductsProps> = ({
  setListResult,
  icon,
  products,
  handleDelete,
}) => {
  const dispatch = useDispatch();
  const token = useSelector(selectUserToken);
  const [categoryList, setCategoryList] = useState([]);

  const getWatchlist = async () => {
    try {
      const { data } = await fetchFiltersList(token);

      if (data?.status === "ok") {
        data?.filters.map((filter) => {
          const tempArray = categoryList;
          tempArray.push(filter?.filter?.category);
          setCategoryList(tempArray);
        });

        dispatch(fetchSavedFilters(data.filters));
      }
    } catch (e) {
      console.log(e.response, "error");
    }
  };

  useEffect(() => {
    getWatchlist();
  }, []);

  return (
    <div className="list-page__products">
      {products?.map((filter, key) => (
        <WatchlistCard
          id={filter?.id}
          className="list-page__product-card"
          key={`${filter?.id}-${key}`}
          title={filter?.filter_string}
          date={moment(filter?.created)
            ?.locale(
              i18n.language === "arab"
                ? "ar-sa"
                : i18n.language === "kur"
                ? "ku"
                : "en-gb"
            )
            .fromNow()}
          newAds={filter?.new_ads}
          filterData={filter?.filter}
          icon={icon}
          setListResult={setListResult}
          handleDelete={handleDelete}
        />
      ))}
    </div>
  );
};

export default Watchlist;
