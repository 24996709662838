import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, useRef, useState, VFC } from "react";
import { Navigation } from "swiper";
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";
import { useDevice } from "~/hooks/useDevice";
import YoutubeEmbed from "../YoutubeEmbed";
import "./styles.scss";

export type ProductCarouselProps = {
  media?: string[];
  youtube?: string;
  accomodationMark?: boolean;
  mobileStyle?: CSSProperties;
  desktopStyle?: CSSProperties;
  showNavigation?: boolean;
};

export type YoutubeSlide = {
  youtube: string;
  type: "youtube";
};

export type ImageSlide = {
  url: string;
  type: "image";
};

export type Slide = ImageSlide | YoutubeSlide;

const createSlides = (media: string[], youtube: string) => {
  const slides: Slide[] = [];
  if (youtube && youtube !== "") {
    slides.push({ youtube, type: "youtube" });
  }
  slides.push(
    ...media.map((mediaItem: string) => ({
      url: mediaItem,
      type: "image",
    }))
  );
  return slides;
};

const ProductCarousel: VFC<ProductCarouselProps> = ({
  media = [],
  youtube,
  accomodationMark = true,
  mobileStyle,
  desktopStyle,
  showNavigation = true,
}) => {
  const [swiper, setSwiper] = useState(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const slides = [...createSlides(media, youtube)];
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const device: string = useDevice();

  const getVideoId = (url?: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url?.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }

  return (
    <Swiper
      onSwiper={setSwiper}
      navigation={{
        prevEl: prevRef.current ? prevRef.current : undefined,
        nextEl: nextRef.current ? nextRef.current : undefined,
      }}
      onRealIndexChange={(swiper) => {
        setCurrentSlide(swiper?.activeIndex + 1);
        setSwiper(swiper);
      }}
      slidesPerView={1}
      className={
        accomodationMark
          ? "product-carousel"
          : "product-carousel__accomodationCarousel"
      }
      modules={[Navigation]}
      style={device === "isMobile" ? mobileStyle : desktopStyle}
      onInit={(swiper) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        swiper.params.navigation.prevEl = prevRef.current;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        swiper.params.navigation.nextEl = nextRef.current;
        // Re-init navigation
        swiper.navigation.destroy();
        swiper.navigation.init();
        swiper.navigation.update();
      }}
    >
      {slides.map((slide, key) => {
        if (slide.type === "image") {
          return (
            <SwiperSlide key={key} className="product-carousel__image-wrapper">
              <div className="product-carousel__image-overlay" />
              <img
                    src={slide.url?.url || slide.url}
                    className="product-carousel__image"
                />
            </SwiperSlide>
          );
        } else if (slide.type === "youtube") {
          return (
            <SwiperSlide key={key}>
              <YoutubeEmbed
                embedId={getVideoId(slide.youtube)}
                className="product-carousel__video"
              />
            </SwiperSlide>
          );
        }
      })}
      {showNavigation && (
        <div
          className={` ${"product-carousel__navigation"} ${
            !accomodationMark
              ? "product-carousel__accomodationNavigation"
              : "product-carousel__main-navigation"
          } `}
        >
          <button
            className="product-carousel__button"
            ref={prevRef}
            onClick={() => {
              setCurrentSlide(swiper?.realIndex + 1);
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <span className="product-carousel__paging">
            {currentSlide} / {slides?.length}
          </span>
          <button
            className="product-carousel__button"
            ref={nextRef}
            onClick={() => {
              setCurrentSlide(swiper?.realIndex + 1);
            }}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      )}
    </Swiper>
  );
};

export default ProductCarousel;
