import { FieldProps } from "formik";
import { isEqual } from "lodash";
import React, { useState } from "react";

export interface DropdownProps<T> {
  items: T[];
  label: string;
  placeholder?: string;
  getLabel: (value: T) => string;
  onChange?: (value: T) => void;
  filterSelected: boolean;
}

const DropdownInput = <T,>({
  items,
  label,
  placeholder,
  getLabel,
  onChange,
  form,
  field,
  filterSelected,
}: DropdownProps<T> & FieldProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const touched = form.touched[field.name];
  const error = form.errors[field.name];

  const dropdownItems = filterSelected
    ? items.filter((item) => !isEqual(item, field.value))
    : items;

  const handleOptionSelect = (option: T) => {
    form.setFieldValue(field.name, option);
    onChange && onChange(option);
    setIsOpen(false);
  };

  return (
    <div
      className={`dropdown-input__wrapper`}
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setIsOpen(false);
        }
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      <span className="dropdown-input__label">{label}</span>
      <button
        type="button"
        tabIndex={0}
        className={`dropdown-input__button ${
          isOpen ? "" : "dropdown-input__button--closed"
        } ${
          touched && error ? "dropdown-input__button--error" : ""
        }`}
        onClick={(e) => {
          setIsOpen(!isOpen);
        }}
      >
        {field.value ? (
          getLabel(field.value)
        ) : (
          <span className="dropdown-input__placeholder">{placeholder}</span>
        )}
      </button>
      <div
        className={`dropdown-input ${isOpen ? "" : "dropdown-input--closed"}`}
      >
        {dropdownItems.map((option, key) => (
          <button
            type="button"
            key={`${getLabel(option)}-${key}`}
            className="dropdown-input__item"
            onClick={() => {
              handleOptionSelect(option);
            }}
          >
            {getLabel(option)}
          </button>
        ))}
      </div>
      {touched && error && <div className="dropdown-input__error-message">{error}</div>}
    </div>
  );
};
export default DropdownInput;
