import React, { useEffect, useState, VFC } from "react";
import {useLocation, useParams} from "react-router-dom";
import {
    fetchAllSubCategories,
    fetchCategories,
    fetchCities, fetchSubCategories,
    selectCategories,
    selectCities,
} from "~/store/slices/categories";
import Filter from "~/components/Filter";
import { useDispatch } from "~/store";
import { useSelector } from "react-redux";
import {
    fetchAllVehicleModels,
    fetchVehicleBrands, fetchVehicleModels,
    selectVehicleBrands,
} from "~/store/slices/vehicles";

import { Categories } from "~/consts/data/categories";
import {
  fetchFilteredProducts,
  selectFilteredProductsByCategory,
  selectsearchVal,
} from "~/store/slices/products";
import "./styles.scss";

import { getCurrentCategory } from "~/helpers";
import Breadcrumbs from "~/components/Breadcrumbs";

import OrderDropdown from "~/components/OrderSelect";
import { OrderType } from "~/consts/data/order";
import { useTranslation } from "react-i18next";
import { useDevice } from "../../hooks/useDevice";

import renderCategory from "../../helpers/renderCategory";
import SearchBar from "~/components/SearchBar";
import LoadingDots from "~/components/LoadingDots";
import List from "./List";
import { useSearchParameter } from "~/hooks/useSearchParameter";
import useNotEnglish from "~/hooks/useNotEnglish";

const ListPage: VFC = () => {
  const { t } = useTranslation();
  const { category: _categoryName } = useParams<{ category?: Categories }>();
  const searchParam = useSearchParameter("search");
  const data = useLocation();
  const categoryName = !_categoryName ? "all" : _categoryName;
  const dispatch = useDispatch();
  const [order, setOrder] = useState<OrderType>("created_desc");
  const [isFetchingData, setIsFetchingData] = useState(true);
  const categories = useSelector(selectCategories);
  const cities = useSelector(selectCities);
  const vehicleBrands = useSelector(selectVehicleBrands);
  const currentCategory = getCurrentCategory(categories, categoryName);
  const currentCategoryValid = currentCategory && categoryName;
  const isLoading = !currentCategoryValid || isFetchingData;
  const device: string = useDevice();
  const notEnglish = useNotEnglish();

  const searchVal = useSelector(selectsearchVal);
  const [selectedPage, setSelectedPage] = useState(0);

  const products =
    useSelector(selectFilteredProductsByCategory(currentCategory)) || [];
  useEffect(() => {
    setIsFetchingData(true);
    !cities.length && dispatch(fetchCities());
    dispatch(fetchCategories())
        .unwrap()
        .then((categories) => {
            const {id: catId} = categories.find(category => category.slug === currentCategory.slug );
            dispatch(fetchSubCategories(catId));
          [1].includes(catId) && dispatch(fetchVehicleBrands());
        });


    // Promise.all([
    //  products?.length < 1 &&
    //   dispatch(
    //     fetchFilteredProducts({
    //       categoryId: currentCategory ? currentCategory?.id : null,
    //       search: searchParam,
    //       order
    //     })
    //   ),
    //   vehicleBrands?.length < 1 &&
    //     dispatch(fetchVehicleBrands())
    //       .unwrap()
    //       .then((brands) => {
    //         dispatch(fetchAllVehicleModels(brands));
    //       }),
    //   cities?.length < 1 && dispatch(fetchCities()),
    //   categories?.length < 1 &&
    //     dispatch(fetchCategories())
    //       .unwrap()
    //       .then((categories) => dispatch(fetchAllSubCategories(categories))),
    // ]).then(() => {
    //   setIsFetchingData(false);
    // });

      // dispatch(
      //     fetchFilteredProducts({
      //       categoryId: currentCategory ? currentCategory?.id : null,
      //       search: searchParam,
      //       order
      //     })
      //   );
  }, [currentCategory.slug]);
    // useEffect(() => {
    //     console.log('vehicleBrands changes --->>>> ', vehicleBrands)
    // }), [vehicleBrands];

  console.log('data:: ', data, ' currentCategory: ', currentCategory, ' categories: ',categories, ' categoryName: ',categoryName, ' products: ',products)

  useEffect(() => {
      console.log('searchParam:: ', searchParam)
    dispatch(
      fetchFilteredProducts({
        search: searchParam,
        order
      })
    );
  }, [searchVal, searchParam, order]);

  const onBrandChange = (value: any, categoryId: number) => {
      if(value?.fieldName === 'vehicle_brand' && value?.vehicle_brand?.id) {
          console.log('value change:: ', value)
          dispatch(fetchVehicleModels(value?.vehicle_brand?.id));
      }
  }

  if (!currentCategoryValid) {
    return <LoadingDots />;
  }
  return (
    <main className="list-page">
      <Breadcrumbs className="list-page__breadcrumbs" />
      <div
        className={"list-page__header"}
        style={
          device === "isDesktop"
            ? {
                background:
                  currentCategory?.slug === "accommodations"
                    ? "linear-gradient(94.31deg, #00FFA3 -70.7%, #7B65BB 123.05%)"
                    : "unset",
                backgroundColor: renderCategory(currentCategory?.slug)
                  .categoryColor,
              }
            : {}
        }
      >
        {device === "isTablet" || device === "isMobile"
          ? renderCategory(currentCategory?.slug).mobIcon
          : renderCategory(currentCategory?.slug).icon}
        <h1 className="list-page__header_text">
          {t(`CATEGORIES.${currentCategory?.slug.toUpperCase()}`)}
        </h1>
      </div>
      {device === "isTablet" || device === "isMobile" ? (
        <div className="list-page__searchBlock">
          <div className="list-page__search">
            <SearchBar selectCounty={false} />
          </div>
          <Filter
            category={currentCategory}
            order={order}
            className="list-page__filter"
            onChange={onBrandChange}
          />
        </div>
      ) : (
        <Filter
          category={currentCategory}
          order={order}
          className="list-page__filter"
          onChange={onBrandChange}
        />
      )}
      <List
        isLoading={false}
        products={searchParam && data.state ? data.state[0] : products}
        order={order}
        setOrder={setOrder}
        currentCategory={currentCategory}
      />
    </main>
  );
};

export default ListPage;
