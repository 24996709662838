import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import Logo from "~/assets/icons/noResultPage-icon.svg";

import "./styles.scss";
import Button from "~/components/Button";
import { useHistory } from "react-router-dom";
import useNotEnglish from "~/hooks/useNotEnglish";
import {useSelector} from "react-redux";
import {selectLang} from "~/store/slices/user";

export type NoResultPageProps = {
  specialMark: boolean;
};

const NoResultPage: FunctionComponent<NoResultPageProps> = ({
  specialMark = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const notEnglish = useNotEnglish();
  const lang = useSelector(selectLang);

  return (
    <main
      className={`${"noResult-page"} ${
        specialMark && "noResult-page__specialMark-style"
      }`}
    >
      <h2 className="noResult-page__title">{t("ADS.NO_RESULT")}</h2>
      <Logo className="noResult-page__logo" />
      <div className="noResult-page__text">{t("ADS.NO_RESULT_TEXT")}</div>
      <div className="noResult-page__noResultBtn">
        <Button onClick={() => history.push(`/${lang}/create-ad`)}>
          {t("BUTTONS.CREATE_NEW_AD")}
        </Button>
      </div>
    </main>
  );
};
export default NoResultPage;
