import React, { useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../Button";
import "./styles.scss";
import FacebookImg from "~/assets/images/facebook.png";
import EnvelopeImg from "~/assets/images/envelope.png";
import AppleImg from "~/assets/images/apple.png";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AppleSignin from "react-apple-signin-auth";

import { loginWith } from "~/api";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, setSocialLogin, setToken} from "~/store/slices/user";
import { addErrorMessage } from "~/store/slices/snackbars";
import { appleRedirectURI, appleServiceId, facebookAppId } from "~/consts";
import { decodeToken } from "react-jwt";

const LoginSection: VFC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [appleResponse, setAppleResponse] = useState("");
  const lang = useSelector(selectLang);

  const responseFacebook = async (response) => {
      console.log('response: ', response)
    if (response?.accessToken) {
      const { data } = await loginWith("facebook", {
        token: response.id,
        email: response?.email || "",
        name: response?.name.split(" ")[0] || "",
        lastname: response?.name.split(" ")[1] || "",
        firebase_token: response.id,
      });

      if (data.status === "ok") {
        dispatch(setToken(data.user_token));
        dispatch(setSocialLogin(true));
        // dispatch(setBusiness(data.user_type === "company"));
      } else {
        dispatch(
          addErrorMessage({
            message: t("FACEBOOK.LOGIN_FAILED"),
            title: t("FACEBOOK.FAILED"),
          })
        );
      }
    } else {
      dispatch(
        addErrorMessage({
          message: t("FACEBOOK.LOGIN_FAILED"),
          title: t("FACEBOOK.FAILED"),
        })
      );
      throw new Error("No access token");
    }
  };

  const loginPostApple = async (response) => {
    const { data } = await loginWith("apple", {
      token: response?.sub,
      email: appleResponse.user?.email || response.email,
      name: response.user?.name?.firstName || "",
      lastname: response.user?.name?.lastName || "",
      firebase_token: response?.sub,
    });

    if (data.status === "ok") {
      dispatch(setToken(data.user_token));
      dispatch(setSocialLogin(true));
      // dispatch(setBusiness(data.user_type === "company"));
    } else {
      dispatch(
        addErrorMessage({
          message: t("APPLE.LOGIN_FAILED"),
          title: t("APPLE.FAILED"),
        })
      );
    }
  }

  useEffect(() => {
    if (appleResponse) {
      const myDecodedTokenObject = decodeToken(
        appleResponse?.authorization?.id_token
      );

      if (myDecodedTokenObject) {
        loginPostApple(myDecodedTokenObject)
      }

    }
  }, [appleResponse]);

  const responseApple = async (response) => {
    setAppleResponse(response);

    if (!response?.authorization?.id_token) {
      dispatch(
        addErrorMessage({
          message: t("APPLE.LOGIN_FAILED"),
          title: t("APPLE.FAILED"),
        })
      );
      throw new Error("No access token");
    }
  };

  return (
    <div className="login-section">
      <h1 className="login-section__title">
        {t("REGISTER.ALREADY_REGISTRED")}
      </h1>
      <p className="login-section__description registration-page__text">
        {t("REGISTER.DESCRIPTION")}
      </p>
      <FacebookLogin
        appId={facebookAppId} //from developers.facebook account
        autoLoad={false}
        fields="name,email"
        scope="public_profile,user_friends"
        callback={responseFacebook}
        icon={FacebookImg}
        render={(renderProps) => (
          <Button
            className="login-section__login-button"
            onClick={renderProps.onClick}
          >
            <img src={FacebookImg} />
            <p className="login-section__login-button_text">
              {t("REGISTER.FACEBOOK")}
            </p>
          </Button>
        )}
      />

      <AppleSignin
        /** Auth options passed to AppleID.auth.init() */
        authOptions={{
          /** Client ID - eg: 'com.example.com' */
          clientId: appleServiceId,
          /** Requested scopes, seperated by spaces - eg: 'email name' */
          scope: "email name",
          /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
          redirectURI: appleRedirectURI,
          /** State string that is returned with the apple response */
          state: "state",
          /** Nonce */
          nonce: "nonce",
          /** Uses popup auth instead of redirection */
          usePopup: true,
        }} // REQUIRED
        /** General props */
        uiType="dark"
        /** className */
        className="apple-auth-btn"
        /** Removes default style tag */
        noDefaultStyle={false}
        /** Allows to change the button's children, eg: for changing the button text */
        buttonExtraChildren="Continue with Apple"
        /** Extra controlling props */
        /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
        onSuccess={(response) => responseApple(response)} // default = undefined
        /** Called upon signin error */
        onError={(error) => console.error(error)} // default = undefined
        /** Skips loading the apple script if true */
        skipScript={false} // default = undefined
        /** Apple image props */
        iconProp={{ style: { marginTop: "10px" } }} // default = undefined
        /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
        render={(props) => (
          <Button className="login-section__login-button apple" {...props}>
            <img src={AppleImg} />
            <p className="login-section__login-button_text">
              {t("REGISTER.APPLE_ID")}
            </p>
          </Button>
        )}
      />
      <Button
        className="login-section__login-button login"
        onClick={() => {
          history.push(`/${lang}/login`);
        }}
      >
        <img src={EnvelopeImg} />
        <p className="login-section__login-button_text">
          {t("REGISTER.LOG_IN")}
        </p>
      </Button>
    </div>
  );
};

export default LoginSection;
