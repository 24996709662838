import { Category } from "~/api";
import { Categories } from "~/consts/data/categories";

export const getCurrentCategory = (
  categories: Category[],
  categoryName: Categories
) => {
  if (categoryName === "all") {
    return {id: null, name: "All", slug: "all"} as Category;
  }
  const currentCategory = categories.find(
    (_category) =>
      _category.name.toLowerCase() === categoryName.toLowerCase() ||
      _category.name.toLowerCase().startsWith(categoryName.toLowerCase()) ||
      categoryName.toLowerCase().startsWith(_category.name.toLowerCase())
  );
  return {
    ...currentCategory,
    slug: categoryName,
  };
};

export const toBase64 = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.toString());
    reader.onerror = (error) => reject(error);
  });

export const getCategoryNameById = (id: number) => {
  switch (id) {
    case 1:
      return "vehicles"
    case 2:
      return "accommodations"
    case 3:
      return "electronics"
    case 4:
      return "other"
    default:
      return "all"
  }
}

export const getDefaultLanguage = () => {
  return localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
}
