export const ACCOMMODATION_BEDROOM_AMOUNT = [
  {
    id: 1,
    name: "1",
  },
  {
    id: 2,
    name: "2",
  },
  {
    id: 3,
    name: "3",
  },
  {
    id: 4,
    name: "4",
  },
  {
    id: 5,
    name: "5",
  },
] as const;

export type AccommodationBedroomAmount =
  typeof ACCOMMODATION_BEDROOM_AMOUNT[number];

export const ACCOMMODATION_SALE_RENT = [
  {
    id: "sale",
    name: "sale",
  },
  {
    id: "rent",
    name: "rent",
  },
] as const;

export type AccommodationSaleRent = typeof ACCOMMODATION_SALE_RENT[number];

export const ACCOMMODATION_TYPES = [
  {
    id: "house",
    name: "house",
  },
  {
    id: "apartment",
    name: "apartment",
  },
  {
    id: "ground",
    name: "ground",
  },
] as const;

export type AccommodationType = typeof ACCOMMODATION_TYPES[number];
