import React, { VFC } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectSellerData, selectUserProfile } from "~/store/slices/user";
import CreditsImage from "../../assets/icons/credit.svg";
import moment from "moment";
import Button from "~/components/Button";
import LoadingDots from "../LoadingDots";
import { useLocation } from "react-router-dom";
import { Loading } from 'react-loading-dot';
import Colors from '~/consts/Colors';
import { Profile } from '~/api';

export type ProfileInfoProps = {
  onClick?: (editable: boolean) => void;
  sellerInfo?: Profile | null | undefined
};

const ProfileInfo: VFC<ProfileInfoProps> = ({onClick, sellerInfo}) => {
  const {t} = useTranslation();
  const currentUser = useSelector(selectUserProfile);
  const seller = sellerInfo;

  if (!seller) {
    return null;
  }

  return (
    <div className="profile-block">
      <h2 className="profile-block__header h2">{ t(`PROFILE.HEADER`) }</h2>
      { seller?.logo &&  seller?.type === "company" && (
        <div className="profile-logoSection">
          <img className="profile-block__logoImg" src={ seller?.logo }/>
        </div>
      ) }
      <div className="profile-block__info">
        <p className="profile-block__info-user">
          { seller?.type === "company" ? seller.company_name : `${ seller.name } ${ seller.lastname }`}
        </p>
      </div>
      { seller?.created && !sellerInfo && (
        <div className="profile-block__info">
          { t("PROFILE.MEMBER") } { moment(seller?.created).format("MMM Do YYYY") }
        </div>
      ) }
      <div className="profile-block__info">
        { seller?.credits && !sellerInfo && (
          <div className="profile-block__info-credits">
            <CreditsImage/>
            <p>{ t("PROFILE.FREE_ADS", {credit: seller?.credits}) }</p>
          </div>
        ) }
      </div>
      { sellerInfo?.phone && (
        <div className="profile-block__info profile-block__info_with-label">
          <label>{ t("PROFILE.PHONE") }</label>
          <a href={ `tel:${ sellerInfo.phone }` }>{ sellerInfo.phone }</a>
        </div>
      ) }

      { !!seller?.email && !sellerInfo && (
        <div className="profile-block__info profile-block__info_with-label">
          <label>{ t("PROFILE.EMAIL") }</label>
          <a href={ `mailto:${ seller.email }` }>{ seller.email }</a>
        </div>
      ) }

      { seller.id===currentUser.id && onClick && (
        <Button
          className="profile-page__cancel-btn"
          onClick={ () => onClick(true) }
        >
          { t("PROFILE.EDIT_PROFILE") }
        </Button>
      ) }
    </div>
  );
};

export default ProfileInfo;
