import moment from "moment";
import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Category, Product } from "~/api";
import ProductCard from "~/components/ProductCard";
import i18n from "~/i18n/config";
import { selectProductsByCategory } from "~/store/slices/products";
import "./more-ads.scss";
import useNotEnglish from "~/hooks/useNotEnglish";
import ProductCardWrap from '~/components/ProductCardWrap';

export type MoreAdsProps = {
  category?: Category;
  products: any;
  sectionTitle?:string;
};

const MoreAds: VFC<MoreAdsProps> = ({ category, products,sectionTitle }) => {
  // const products = useSelector(selectProductsByCategory(category));
  const { t } = useTranslation();
  if (!products?.length) return null;
  return (
    <div className="product-details-page__more-ads-container">
      <div className="product-details-page__more-ads">
        <h2 className="product-details-page__more">{sectionTitle ? sectionTitle : t("ADS.SELLER") }</h2>
        <div className="product-details-page__cards">
          { products?.slice(0, 3)?.map((product:Product) => (
            <ProductCardWrap {...product}/>
          )) }
        </div>
      </div>
    </div>

  );
};

export default MoreAds;
