import React, { useEffect } from "react";
import { throttle } from "lodash";
const useComputedVariables = () => {
  const setVh = () => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    );
  };
  useEffect(() => {
    const updateVh = () => {
      throttle(setVh, 100);
    };
    window.addEventListener("resize", updateVh);
    return () => {
      window.removeEventListener("resize", updateVh, false);
    };
  }, [document]);
};

export default useComputedVariables;
