import moment from "moment";
import React, { ReactNode, VFC } from "react";
import ProductCard from "~/components/ProductCard";
import { Product } from "~/api/product";
import { Category } from "~/api";
import i18n from "~/i18n/config";
import { useSelector } from "react-redux";
import { getCurrentCategory } from "~/helpers";
import { Categories } from "~/consts/data/categories";
import { useParams } from "react-router-dom";
import { selectCategories } from "~/store/slices/categories";

export type productsProps = {
  products: Product[];
  category?: Category;
  icon?: ReactNode;
  handleDelete?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => void;
};

const Products: VFC<productsProps> = ({
  products,
  category,
  icon,
  handleDelete,
}) => {
  const categories = useSelector(selectCategories);
  const { category: _categoryName } = useParams<{ category?: Categories }>();
  const categoryName = !_categoryName ? "all" : _categoryName;
  const currentCategory = getCurrentCategory(categories, categoryName);

  return (
    <div className="list-page__products">
      {products?.map((product, key) => {
        return (
          <ProductCard
            {...product}
            logo={product?.profile_logo || ''}
            className={`${"list-page__product-card"}
          ${currentCategory.name === "Accommodation" && "accomodation-card"}`}
            key={`${product?.id}-${key}`}
            description={product?.info}
            location={product?.city}
            date={moment(product?.created)
              .locale(
                i18n.language === "arab"
                  ? "ar-sa"
                  : i18n.language === "kur"
                  ? "ku"
                  : "en-gb"
              )
              .format("ddd HH:mm")}
            priceTag={product?.price?.toString()}
            categorySlug={category?.slug}
            icon={icon}
            handleDelete={handleDelete}
          />
        );
      })}
    </div>
  );
};

export default Products;
