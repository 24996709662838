import React, { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import RegistrationFormFirstStep, {
  RegistrationModelFirstStep,
} from "../RegistrationForm/RegistrationFormFirstStep";
import BusinessFormSecondStep, {
  BusinessModelSecondStep,
} from "~/components/BusinessForm/BusinessFormSecondStep";

export type BusinessFormProps = {
  onSubmit: (values: {
    business_name: string;
    password: string;
    phone: string;
    name: string;
    logo: [];
    passwordRepeat: string;
    rememberMe: boolean;
    agree: boolean;
    emailRepeat: string;
    type: "private" | "business";
    email: string;
    lastname: string;
  }) => void;
  type: "private" | "business";
  loading: boolean;
};
const BusinessForm: VFC<BusinessFormProps> = ({ onSubmit, type, loading }) => {
  const { t } = useTranslation();
  const [registrationStep, setRegistrationStep] = useState(1);
  const [firstStepData, setFirstStepData] =
    useState<RegistrationModelFirstStep>(null);
  const submitFirstPage = (values: RegistrationModelFirstStep) => {
    setFirstStepData(values);
    setRegistrationStep(2);
  };
  const handleSubmit = (values: BusinessModelSecondStep) => {
    onSubmit({ ...firstStepData, ...values });
  };
  const initialValues: RegistrationModelFirstStep =
    firstStepData === null
      ? {
          email: "",
          password: "",
          emailRepeat: "",
          passwordRepeat: "",
          rememberMe: false,
          type,
        }
      : firstStepData;

  return (
    <div>
      {registrationStep === 1 ? (
        <RegistrationFormFirstStep
          initialValues={initialValues}
          onSubmit={submitFirstPage}
          type={type}
        />
      ) : (
        <BusinessFormSecondStep
          onSubmit={handleSubmit}
          goBack={() => {
            setRegistrationStep(1);
          }}
          loading={loading}
        />
      )}
    </div>
  );
};

export default BusinessForm;
