import React, { ReactElement, SVGProps, VFC } from "react";
import useMediaQuery from "~/hooks/useMediaQuery";
import i18n from "i18next";
import OtherCategorySvg from "~/assets/icons/other-category.svg";
import AccommodationCategorySvg from "~/assets/icons/accommodation-category.svg";
import ElectronicsCategorySvg from "~/assets/icons/electronics-category.svg";
import VehiclesCategorySvg from "~/assets/icons/vehicles-category.svg";
import OtherAngleSvg from "~/assets/icons/other-angle.svg";
import AccommodationAngleSvg from "~/assets/icons/accommodation-angle.svg";
import ElectronicsAngleSvg from "~/assets/icons/electronics-angle.svg";
import VehiclesAngleSvg from "~/assets/icons/vehicles-angle.svg";
import "./styles.scss";
import { Categories } from "~/consts/data/categories";
import { useDevice } from "../../hooks/useDevice";

export type CategoryButtonProps = {
  variant: Categories;
  className?: string;
  onClick?: () => void;
  iconSize?: "small" | "big";
};

export type CategoryButton = {
  Icon?: (props: SVGProps<SVGElement>) => ReactElement;
  AngleIcon: (props: SVGProps<SVGElement>) => ReactElement;
};

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

const buttonVariants: PartialRecord<Categories, CategoryButton> = {
  vehicles: {
    Icon: VehiclesCategorySvg,
    AngleIcon: VehiclesAngleSvg,
    
  },
  accommodations: {
    Icon: AccommodationCategorySvg,
    AngleIcon: AccommodationAngleSvg,
  },
  electronics: {
    Icon: ElectronicsCategorySvg,
    AngleIcon: ElectronicsAngleSvg,
  },
  other: {
    Icon: OtherCategorySvg,
    AngleIcon: OtherAngleSvg,
  },
  all: {
    Icon: null,
    AngleIcon: OtherAngleSvg,
  },
};
const CategoryButton: VFC<CategoryButtonProps> = ({
  variant,
  className = "",
  iconSize = "small",
  onClick,
}) => {
  if (!buttonVariants[variant]) return <></>;
  const { Icon, AngleIcon } = buttonVariants[variant];
  return (
    <button
      className={`category-button ${className}`}
      onClick={onClick}
      type="button"
    >
      {Icon &&
        (iconSize === "big" ? (
          <Icon />
        ) : (
          <Icon width="40" height="32" viewBox="0 0 50 30" />
        ))}
      <span
        className="category-button__label"
        style={
          document.body.dir === "ltr"
            ? { marginLeft: Icon ? 15 : 0 }
            : { marginRight: Icon ? 15 : 0 }
        }
      >
        {i18n.t(`CATEGORIES.${variant.toLocaleUpperCase()}`)}
      </span>
      <AngleIcon className="category-button__angle" />
    </button>
  );
};

export default CategoryButton;
