import { userReducer, UserState } from "./slices/user/reducer";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch as _useDispatch } from "react-redux";
import { productsReducer } from "./slices/products";
import { categoriesReducer } from "./slices/categories";
import { vehiclesReducer } from "./slices/vehicles";
import { snackbarsReducer } from "./slices/snackbars";
import storage from "redux-persist/lib/storage";

const userPersistConfig = {
  key: "user",
  storage: storage,
};

export const store = configureStore({
  reducer: {
    products: productsReducer,
    categories: categoriesReducer,
    vehicles: vehiclesReducer,
    user: persistReducer<UserState>(userPersistConfig, userReducer),
    snackbars: snackbarsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useDispatch = () => _useDispatch<AppDispatch>();
