import React, { VFC, useEffect, useState } from "react";
import SearchBar from "~/components/SearchBar";
import CategoryBlock from "~/components/CategoryBlock";
import "./styles.scss";
import ProductCard from "~/components/ProductCard";
import { fetchLatestProducts, selectProducts } from "~/store/slices/products";
import { useSelector } from "react-redux";
import { useDispatch } from "~/store";
import moment from "moment";
import Button from "~/components/Button";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import i18n from "~/i18n/config";
import LoadingDots from "~/components/LoadingDots";
import { selectLang } from "~/store/slices/user";
import ProductCardWrap from '~/components/ProductCardWrap';
import { Product } from '~/api';

const StartPage: VFC = () => {
  const products = useSelector(selectProducts);
  const dispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const lang = useSelector(selectLang);

  // history.listen((location, action) => {
  //   console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
  //   console.log(`The last navigation action was ${action}`)
  //   dispatch(fetchLatestProducts()).then(() => setIsFetchingData(false));
  // })

  useEffect(() => {
    dispatch(fetchLatestProducts()).then(() => setIsFetchingData(false));
  }, []);


  const productsSlice = products.slice(0,6);

  console.log("----------------------------------");
  console.log({
    products,
    productsSlice
  });
  console.log("----------------------------------");

  return (
    <section className="start-page">
      <div className="start-page__content">
        <h1 className="start-page__title">{ t("HOME.HOME_PAGE_TITLE") }</h1>
        <div className="start-page__search">
          <SearchBar selectCounty={ false }/>
        </div>
        <div className="start-page__categories">
          <CategoryBlock/>
        </div>

        { isFetchingData ? (
          <LoadingDots/>
        ) : (
          <>
            { !!productsSlice.length && (
              <h2 className="start-page__latest">{ t("ADS.LATEST") }</h2>
            ) }
            <div className="start-page__product-cards">
              { productsSlice.map(
                (product:Product) =>{
                  console.log({pr:product});
                  return (
                    <>
                      <ProductCardWrap {...product}/>
                    </>
                  )
                }

                ) }
            </div>
            <div className="start-page__load-all-button-wrapper">
              <Button
                className="start-page__load-all-button"
                onClick={ () => {
                  history.push(`/${ lang }/products/all`);
                } }
              >
                { t("BUTTONS.SEE_ALL") }
              </Button>
            </div>
          </>
        ) }
      </div>
    </section>
  );
};

export default StartPage;
