import React, { useState, useEffect } from "react";
import {Provider} from "react-redux";
import { persistor, store } from "~/store";
import Routes from "~/routers";
import "./styles/main.scss";
import useComputedVariables from "./styles/useComputedVariables";
import { PersistGate } from "redux-persist/integration/react";
import {setToken} from "./store/slices/user";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getDefaultLanguage } from '~/helpers';

const App = () => {
  useComputedVariables();
  const history = useHistory();

  const [hasRendered, setHasRendered] = useState(false);
  const [isHomePge, setIsHomePge] = useState(false);
  const { i18n } = useTranslation();
  const token = localStorage.getItem("auth");
  const lang = getDefaultLanguage();

  if (i18n.language === "arab" || i18n.language === "kur") {
    document.body.dir = i18n.dir("arb");
    document.body.classList.add("east");
  } else {
    document.body.classList.remove("east");
    document.body.dir = i18n.dir();
  }

  useEffect(() => {
    if (!token && history) {
      history.push(`/${lang}/`);
    }
  }, [token]);

  useEffect(() => {
    try {
      const useToken = setToken(token);
      store.dispatch(useToken);
    } catch (e) {
      console.warn("sign-in required... ");
    }

    setHasRendered(true);
  }, []);

  if (!hasRendered) {
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  );
};

export default App;
