import React, { useEffect } from "react";
import Breadcrumbs from "~/components/Breadcrumbs";
import "./styles.scss";
import ProfileInfo from "~/components/ProfileBlock/ProfileInfo";
import {
  fetchSellerProfile,
  selectSellerProfile,
  selectSellerProfileLoading,
  selectSellerProfileError,
  selectUserProfile
} from "~/store/slices/user";
import { useDispatch, useSelector } from "react-redux";
import { selectUserToken } from "~/store/slices/user";
import { useParams } from "react-router-dom";
import MoreAds from '~/pages/ProductDetailsPage/MoreAds';
import { Product, ProductDetailsType, Profile, ProfileResponse } from '~/api';
import { fetchProductDetails, selectProductDetails } from '~/store/slices/products';
import { Loading } from 'react-loading-dot';
import Colors from '~/consts/Colors';

const SellerProfilePage = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectUserToken);
  const {adId} = useParams<{ adId: string; }>();
  const profile = useSelector(selectUserProfile);
  const product: ProductDetailsType = useSelector(selectProductDetails);
  const seller: Profile = useSelector(selectSellerProfile);
  const isLoading: boolean = useSelector(selectSellerProfileLoading);
  const hasError: boolean = useSelector(selectSellerProfileError);

  useEffect(() => {
    dispatch(fetchProductDetails({productId: Number(adId), token}));
  }, []);

  useEffect(() => {
    const sellerId = product?.user_id;
    const categoryId = product?.category_id;
    if (sellerId) {
      dispatch(fetchSellerProfile({token: token, profileId: sellerId, categoryId: categoryId}));
    }
  }, [product]);

  const loading = isLoading || !product || !seller;
  if (loading) {
    return <Loading dots={ 3 } background={ Colors.PINK }/>;
  }
  const ads: Product[] = seller?.ads ? seller?.ads : [];
  return (
    <>
      <main className="profile-page">
        <div className="profile-page__breadcrumbs">
          <Breadcrumbs/>
        </div>

        <div className="profile-page__container">
          <ProfileInfo sellerInfo={seller}/>
        </div>
      </main>
      <MoreAds products={ ads }/>
    </>

  );
};

export default SellerProfilePage;
