import { CATEGORIES } from "./../../../api/categories";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  SubCategory,
  fetchCategories as _fetchCategories,
  fetchSubCategories as _fetchSubCategories,
  fetchCities as _fetchCities,
  City,
  Category,
} from "~/api";
import { ALL_CATEGORIES } from "~/consts/data/categories";

const mapCategorySlugs = (categories: Category[], slugs: readonly string[]) => {
  return categories.map((category) => {
    const name = category.name.toLocaleLowerCase();
    const slug = slugs.find(
      (slug) => name === slug || name.startsWith(slug) || slug.startsWith(name)
    );
    return { ...category, slug } as Category;
  });
};

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async () => {
    const { data } = await _fetchCategories();
    if (data.status === "error") {
      throw data.message;
    }
    const categories = mapCategorySlugs(data.data, ALL_CATEGORIES);
    return categories ;
  }
);

export const fetchSubCategories = createAsyncThunk(
  "categories/fetchSubCategories",
  async (id: number): Promise<SubCategory[]> => {
    const { data } = await _fetchSubCategories(id);
    return data.data.map((category) => ({ ...category, parentId: id }));
  }
);

export const fetchAllSubCategories = createAsyncThunk(
  "categories/fetchAllSubCategories",
  async (categories: Category[]) => {
    const subCategories = categories.map(async (category) => {
      const { data } = await _fetchSubCategories(category.id);
      return data.data.map(
        (subCategory) =>
          ({
            ...subCategory,
            parentId: category.id,
          } as SubCategory)
      );
    });
    const allSubCategories = await (
      await Promise.all(subCategories)
    ).flatMap((subCategory) => subCategory);
    return allSubCategories;
  }
);

export const fetchCities = createAsyncThunk(
  "categories/fetchCities",
  async (): Promise<City[]> => {
    const { data } = await _fetchCities();
    return data.data;
  }
);
