import React, { useState, VFC } from "react";
import { Field, Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import RegistrationFormFirstStep, {
  RegistrationModelFirstStep,
} from "./RegistrationFormFirstStep";
import RegistrationFormSecondStep, {
  RegistrationModelSecondStep,
} from "./RegistrationFormSecondStep";
import { RegistrationModel } from "~/api";

export type RegistrationFormProps = {
  onSubmit: (values: RegistrationModel) => void;
  type: "private" | "business";
  loading: boolean;
};

const RegistrationForm: VFC<RegistrationFormProps> = ({
  onSubmit,
  type,
  loading,
}) => {
  const { t } = useTranslation();
  const [registrationStep, setRegistrationStep] = useState(1);
  const [firstStepData, setFirstStepData] =
    useState<RegistrationModelFirstStep>(null);
  const submitFirstPage = (values: RegistrationModelFirstStep) => {
    setFirstStepData(values);
    setRegistrationStep(2);
  };
  const handleSubmit = (values: RegistrationModelSecondStep) => {
    onSubmit({ ...firstStepData, ...values });
  };
  const initialValues: RegistrationModelFirstStep =
    firstStepData === null
      ? {
          email: "",
          password: "",
          emailRepeat: "",
          passwordRepeat: "",
          rememberMe: false,
          type,
        }
      : firstStepData;

  return (
    <div>
      {registrationStep === 1 ? (
        <RegistrationFormFirstStep
          initialValues={initialValues}
          onSubmit={submitFirstPage}
          type={type}
        />
      ) : (
        <RegistrationFormSecondStep
          onSubmit={handleSubmit}
          goBack={() => {
            setRegistrationStep(1);
          }}
          loading={loading}
        />
      )}
    </div>
  );
};

export default RegistrationForm;
