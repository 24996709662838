import React, { VFC } from "react";
import Breadcrumbs from "~/components/Breadcrumbs";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import useNotEnglish from "~/hooks/useNotEnglish";

const PrivacyPage: VFC = () => {
  const { t } = useTranslation();
  const notEnglish = useNotEnglish();

  return (
    <main className={`privacy-page ${notEnglish && 'east'}`}>
      <Breadcrumbs className="privacy-page__breadcrumbs" />

      <div className="privacy-page__title">
        <h1 className="privacy-page__text">{t("FOOTER.LINKS.PRIVACYPOLICY")}</h1>
      </div>

      <div className="privacy-page__content" dangerouslySetInnerHTML={{__html: t("PAGES.PRIVACY.TEXT")}} />
    </main>
  );
};

export default PrivacyPage;
