import React, { useCallback, useEffect, useState, VFC } from "react";
import { useDispatch } from "react-redux";
import { fetchSaved, fetchSavedDelete, Product } from "~/api";
import Pagination from "~/components/Pagination";
import OrderDropdown from "~/components/OrderSelect";
import Colors from "~/consts/Colors";
import { OrderType } from "~/consts/data/order";
import {
  fetchSavedProduct,
  removeSavedProduct,
} from "~/store/slices/products";
import Products from "../ListPage/Products";
import { chunk } from "lodash";
import { useTranslation } from "react-i18next";
import TrashSvg from "../../assets/icons/trash-icon.svg";
import LoadingDots from "~/components/LoadingDots";

export type SavedTabProps = {
  order: OrderType;
  setOrder: (c: OrderType) => void;
  savedProducts: Product[];
};

const SavedTab: VFC = ({
  order,
  setOrder,
  savedProducts
}: SavedTabProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pageSize = 4;
  const [selectedPage, setSelectedPage] = useState(0);
  const [saved, setSaved] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);

  const setSavedItems = useCallback(async () => {
    const token = localStorage.getItem("auth");
    try {
      const { data } = await fetchSaved(token);
      if (data.status === "ok") {
        dispatch(fetchSavedProduct(data.ads));
        setSaved(!saved);
        setIsFetchingData(false);
      }
    } catch (e) {
      console.log(e.response, "error");
    }
  }, []);

  useEffect(() => {
    setSavedItems();
  }, []);

  const isLoading = !savedProducts.length && isFetchingData;
  if (isLoading) {
    return <LoadingDots />;
  }

  const handleDelete = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation()
    const token = localStorage.getItem("auth");
    try {
      const { data } = saved
        ? await fetchSavedDelete({ ad_id: id, token })
        : null;
      if (data.status === "ok") {
        dispatch(removeSavedProduct(id));
      }
    } catch (e) {
      console.log(e?.response, "error");
    }
  };

  const productPages = chunk(savedProducts, pageSize);

  return (
    <>
      <div className="saved-product-page__title">
        <h1 className="saved-product-page__title__text">{t("SAVED.ADS")}</h1>
      </div>

      <div>
        <div className="list-page__controls">
          <span style={{ color: Colors.DARK_GREY, fontWeight: "bold" }}>
            {t("FILTERS.ShowingSavedCount", {
              adsCount: savedProducts.length,
            })}
          </span>
          <OrderDropdown
            onSelect={(order) => {
              setOrder(order);
            }}
            value={order}
          />
        </div>
        <div className="saved-product-page__list">
          <Products
            products={productPages[selectedPage]}
            handleDelete={handleDelete}
            icon={<TrashSvg className="saved-product-page__trash-icon" />}
          />
          <Pagination
            className="saved-product-page__pagination"
            setPage={setSelectedPage}
            selectedPage={selectedPage}
            pageCount={productPages.length}
            itemsOnCurrentPage={productPages[selectedPage]?.length || 0}
            itemCount={savedProducts.length}
            pageSize={pageSize}
          />
        </div>
      </div>
    </>
  );
};

export default SavedTab;
