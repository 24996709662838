import React, { ReactNode, VFC } from "react";

export type PaginationItemProps = {
  value?: string | number | ReactNode;
  onClick?: () => void;
  selected?: boolean;
  color?: string;
};
const PaginationItem: VFC<PaginationItemProps> = ({
  value,
  onClick,
  selected,
  color,
}) => {
  if (!onClick) {
    <li
      className={`pagination-item ${
        selected ? "pagination-item--selected" : ""
      }`}
    >
      <div className="pagination-button">{value}</div>
    </li>;
  }

  return (
    <li
      className={`pagination-item ${
        selected ? "pagination-item--selected" : ""
      }`}
    >
      <button
        className="pagination-button"
        type="button"
        onClick={onClick}
        style={{ backgroundColor: selected ? color : "white" }}
      >
        {value}
      </button>
    </li>
  );
};
export default PaginationItem;
