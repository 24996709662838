import { Field, FieldAttributes, useField } from "formik";
import React, { ChangeEvent, useState, VFC } from "react";
import FilterItem from "./FilterItem";

export type NumberFieldProps = {
  label: string;
  max: number;
  min: number;
  placeholder: string;
  onChange: (value: number) => void;
};

const NumberField: VFC<FieldAttributes<NumberFieldProps>> = (props) => {
  const { label, onChange, max, min, placeholder } = props;
  const [field, meta] = useField(props);
  const [isOpen, setIsOpen] = useState(false);
  const newField = {
    ...field,
    value: field.value === undefined ? "" : field.value,
    onChange: (e: string | React.ChangeEvent<any>) => {
      field.onChange(e);
      onChange(e.currentTarget.value);
    },
  };

  return (
    <FilterItem
      label={label}
      value={String(newField.value)}
      isOpen={isOpen}
      numberType
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <input
        {...newField}
        placeholder={placeholder}
        id={`${label}-number-input`}
        name={label}
        type="number"
        max={max}
        min={min}
        className="number-input"
      />
    </FilterItem>
  );
};
export default NumberField;
