import { ProductDetailsType } from "~/api";
import { v4 } from "uuid";
import { Product } from "~/api/product";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchLatestProducts,
  fetchFilteredProducts,
  fetchProductDetails,
  fetchSavedProduct,
  removeSavedProduct,
  fetchSavedFilters,
  removeSavedFilters,
} from "./actions";
import { VehicleFilterFields } from "~/components/Filter/Forms/VehicleFilter";
import { AccommodationsFilterFields } from "~/components/Filter/Forms/AccommodationsFilter";
import { CreateAdFields } from "~/components/CreateAdForm/types";
import { OtherFilterFields } from "~/components/Filter/Forms/OtherFilter";
import { ElectronicsFilterFields } from "~/components/Filter/Forms/ElectronicsFilter";

export type Filter =
  | VehicleFilterFields
  | AccommodationsFilterFields
  | OtherFilterFields
  | ElectronicsFilterFields;
export type StoredFilter = {
  filter: Filter;
  date: Date;
  id: string;
};
export interface ProductsState {
  products: Product[];
  filteredProducts: Product[];
  selectedProduct?: ProductDetailsType;
  filters: StoredFilter;
  createdAdProducts: CreateAdFields;
  showPreviewForm: boolean;
  savedProduct: Product[];
  savedFilters: Product[];
  dataFromWatchlist: any;
  searchVal: string;
}

const initialState: ProductsState = {
  products: [],
  filteredProducts: [],
  selectedProduct: null,
  createdAdProducts: null,
  showPreviewForm: false,
  savedProduct: [],
  savedFilters: [],
  filters: {
    category_id: "",
    city: { id: "", name: "" },
    sub_category: { id: "", name: "" },
    vehicle_brand: { id: "", name: "" },
    vehicle_model: { id: "", name: "" },
    vehicle_year_max: "",
    vehicle_year_min: "",
    vehicle_milage_min: "",
    vehicle_milage_max: "",
    vehicle_fuel: "",
    vehicle_gearbox: "",
    accommodation_square_meter_min: "",
    accommodation_square_meter_max: "",
    accommodation_bedrooms_min: "",
    accommodation_bedrooms_max: "",
    accommodation_sale_rent: "",
    accommodation_type: "",
    price_min: "",
    price_max: "",
  },
  dataFromWatchlist: [],
  searchVal: "",
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
    },
    addFilter: (state, action: PayloadAction<Filter>) => {
      state.filters.push({
        filter: action.payload,
        date: new Date(),
        id: v4(),
      });
    },
    setCreateAdProduct: (state, action: PayloadAction<CreateAdFields>) => {
      state.createdAdProducts = action.payload;
    },
    setPreviewForm: (state, action: PayloadAction<boolean>) => {
      state.showPreviewForm = action.payload;
    },
    setDataFromWatchList: (state, action: PayloadAction<any>) => {
      state.dataFromWatchlist = action.payload;
    },
    setSearchVal: (state, action: PayloadAction<string>) => {
      state.searchVal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLatestProducts.fulfilled, (state, action) => {
        state.products = action.payload;
      })
      .addCase(fetchFilteredProducts.fulfilled, (state, action) => {
        state.filteredProducts = action.payload;
      })
      .addCase(fetchSavedFilters, (state, action) => {
        state.savedFilters = action.payload;
      })
      .addCase(fetchProductDetails.fulfilled, (state, action) => {
        state.selectedProduct = action.payload;
      })
      .addCase(fetchSavedProduct, (state, action) => {
        state.savedProduct = action.payload;
      })
      .addCase(removeSavedProduct, (state, action) => {
        state.savedProduct.splice(
          state.savedProduct.findIndex(
            (product) => product.id === action.payload
          ),
          1
        );
      })
      .addCase(removeSavedFilters, (state, action) => {
        state.savedFilters.splice(
          state.savedFilters.findIndex(
            (product) => product.id === action.payload
          ),
          1
        );
      });
  },
});

export const {
  setProducts,
  addFilter,
  setCreateAdProduct,
  setPreviewForm,
  setDataFromWatchList,
  setSearchVal,
} = productsSlice.actions;
export const productsReducer = productsSlice.reducer;
