import React, { VFC } from "react";
import { Field, Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import TextInput from "../FormInputs/TextInput";
import * as Yup from "yup";
import "./styles.scss";
import i18next from "i18next";
import CheckboxInput from "../FormInputs/CheckboxInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

export type RegistrationModelSecondStep = {
  name: string;
  lastname: string;
  phone: string;
  agree: boolean;
};

export type RegistrationFormProps = {
  onSubmit: (values: RegistrationModelSecondStep) => void;
  loading: boolean;
  goBack: () => void;
};

const RegistrationSchema = Yup.object().shape({
  name: Yup.string().required(i18next.t("ERRORS.NAME_REQ")),
  lastname: Yup.string().required(i18next.t("ERRORS.SURNAME_REQ")),
  phone: Yup.string()
    .matches(/^[0-9 -+]+$/, i18next.t("ERRORS.PHONE_INVALID"))
    .required(i18next.t("ERRORS.PHONE_REQ")),
  agree: Yup.bool().oneOf([true], i18next.t("ERRORS.AGREE_REQ")),
});

const RegistrationFormSecondStep: VFC<RegistrationFormProps> = ({
  onSubmit,
  loading,
  goBack,
}) => {
  const { t } = useTranslation();
  const initialValues: RegistrationModelSecondStep = {
    name: "",
    lastname: "",
    phone: "",
    agree: false,
  };

  const handleSubmit = (values: RegistrationModelSecondStep) => {
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={RegistrationSchema}
    >
      {(formik: FormikProps<RegistrationModelSecondStep>) => {
        return (
          <>
            <Button onClick={goBack} className="registration-form__back-button">
              <FontAwesomeIcon icon={faAngleLeft} />
              {t("common.BACK")}
            </Button>
            <form className="registration-form">
              <Field
                type="name"
                name="name"
                component={TextInput}
                placeholder={t("PLACEHOLDERS.NAME")}
                label={t("LABELS.NAME")}
              />
              <Field
                type="lastname"
                name="lastname"
                component={TextInput}
                placeholder={t("PLACEHOLDERS.SURNAME")}
                label={t("LABELS.LASTNAME")}
              />
              <Field
                type="phone"
                name="phone"
                component={TextInput}
                placeholder={t("PLACEHOLDERS.PHONE")}
                label={t("LABELS.PHONE")}
              />
              <Field
                name="agree"
                component={CheckboxInput}
                label={t("REGISTER.AGREE_TERMS")}
                linkText={t("REGISTER.AGREE_TERMS_TEXTLINK")}
                link={'/terms-of-use'}
              />
              <Button
                onClick={formik.handleSubmit}
                type="submit"
                className="registration-form__submit"
                loading={loading}
              >
                {t("BUTTONS.REGISTER")}
              </Button>
            </form>
          </>
        );
      }}
    </Formik>
  );
};

export default RegistrationFormSecondStep;
