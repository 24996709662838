import { RootState } from "~/store";
import { Category } from "~/api";

export const selectProducts = (store: RootState) => store.products.products;

export const selectProductsByCategory =
  (category: Category) => (store: RootState) => {
    const products = store.products.products;
    if (category?.id === null) {
      return products;
    }
    return products?.filter((product) => product?.category === category?.id);
  };

export const selectFilteredProductsByCategory =
  (category: Category) => (store: RootState) => {
    const products = store.products.filteredProducts;
    if (category?.id === null) {
      return products;
    }
    return products?.filter((product) => product?.category === category?.id);
  };

export const selectFilteredProducts = (store: RootState) =>
  store.products.filteredProducts;

export const selectProductDetails = (store: RootState) =>
  store.products.selectedProduct;

export const selectCreatedAdProducts = (store: RootState) =>
  store.products.createdAdProducts;

export const selectShowPreviewForm = (store: RootState) =>
  store.products.showPreviewForm;

export const selectSavedProducts = (store: RootState) =>
  store.products.savedProduct;

export const selectSavedFilters = (store: RootState) =>
  store.products.savedFilters;

export const selectDataFromWatchlist = (store: RootState) =>
  store.products.dataFromWatchlist;

export const selectsearchVal = (store: RootState) =>
  store.products.searchVal;

