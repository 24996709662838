import i18n from "i18next";
import en from "./locales/en.json";
import { initReactI18next } from "react-i18next";
import arab from "./locales/arab.json";
import kur from "./locales/kur.json";
import LanguageDetector from "i18next-browser-languagedetector";

export const resources = {
  en,
  arab,
  kur,
} as const;

i18n.use(initReactI18next).use(LanguageDetector).init({
  lng: "en",
  fallbackLng: "en",
  debug: true,
  resources,
  keySeparator: false,
  nsSeparator: ".",
});

export default i18n;
