import React, { VFC } from "react";
import { Field, Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import TextInput from "../FormInputs/TextInput";
import * as Yup from "yup";
import "./styles.scss";
import i18next from "i18next";
import { ClipLoader } from "react-spinners";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectLang} from "~/store/slices/user";

export type LoginModel = {
  email: string;
  password: string;
};

export type LoginFormProps = {
  onSubmit: (values: LoginModel) => void;
  loading: boolean;
};

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18next.t("ERRORS.EMAIL_INVALID"))
    .required(i18next.t("ERRORS.EMAIL_REQ")),
  password: Yup.string()
    .min(5, i18next.t("ERRORS.PASS_INVALID"))
    .required(i18next.t("ERRORS.PASS_REQ")),
});

const LoginForm: VFC<LoginFormProps> = ({ onSubmit, loading }) => {
  const { t } = useTranslation();
  const lang = useSelector(selectLang);

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = (values: LoginModel) => {
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={LoginSchema}
    >
      {(formik: FormikProps<LoginModel>) => {
        return (
          <form className="registration-form">
            <Field
              type="email"
              name="email"
              component={TextInput}
              placeholder={t("PLACEHOLDERS.LOGIN_EMAIL")}
              label={t("LABELS.EMAIL")}
            />
            <Field
              type="password"
              name="password"
              component={TextInput}
              placeholder={t("PLACEHOLDERS.LOGIN_PASSWORD")}
              label={t("LABELS.PASSWORD")}
            />
            <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
              <label style={{ display: "flex", alignItems: "center" }}>
                <Field type="checkbox" name="rememberMe" />
                <p>{t("LABELS.REMEMBER_ME")}</p>
              </label>
              <Link to={`/${lang}/password-recovery`} style={{color: '#000', fontFamily: 'Tajawal-Light'}}><p>{t("REGISTER.FORGOT_PASS")}</p></Link>
            </div>
            <Button
              onClick={formik.handleSubmit}
              type="submit"
              className="registration-form__submit"
            >
              {loading ? (
                <ClipLoader color="white" size={20} />
              ) : (
                t("BUTTONS.LOG_IN")
              )}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
