import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Category, City, SubCategory, VehicleBrand, VehicleModel } from "~/api";
import { selectCategories, selectCities } from "~/store/slices/categories";

import FilterForm, { numberField, radioField } from "./FilterForm";
import Button from "~/components/Button";

import { VehicleFuel, VehicleGearbox } from "~/consts/data/vehicles";

import Colors from "~/consts/Colors";
import { ClipLoader } from "react-spinners";

export type VehicleFilterFields = {
  vehicle_brand?: VehicleBrand;
  vehicle_model?: VehicleModel;
  city?: City;
  sub_category?: SubCategory;
  price_min?: number;
  price_max?: number;
  vehicle_year_min?: number;
  vehicle_year_max?: number;
  vehicle_milage_min?: number;
  vehicle_milage_max?: number;
  vehicle_gearbox?: VehicleGearbox;
  vehicle_fuel?: VehicleFuel;
  category_id: string;
};

export type VehicleFilterProps = {
  category: Category;
  filterValues?: VehicleFilterFields;
  setFilterValues: React.Dispatch<React.SetStateAction<any>>;
  onSubmit: (values: VehicleFilterFields) => void;
  loading: boolean;
};

const VehicleFilter: FC<VehicleFilterProps> = ({
  category,
  filterValues,
  setFilterValues,
  onSubmit,
  loading,
}) => {
  const { t } = useTranslation();
  const [_filterValues, _setFilterValues] = useState(filterValues);
  const cities = useSelector(selectCities);
  const categoryField = useSelector(selectCategories);

  const allFilter = [
    radioField("city", t("FILTERS.city"), cities),
    numberField("price_min", t("FILTERS.price_min")),
    numberField("price_max", t("FILTERS.price_max")),
    radioField("sub_category", t("FILTERS.sub_category"), categoryField),
  ];
  let initialValues = {};
  allFilter.map((filter) => {
    initialValues = {
      ...initialValues,
      [filter.name]: "",
    };
  });

  const handleSetFilterValues = (values: any) => {
    _setFilterValues(values);
    setFilterValues(values);
  };

  const filters = allFilter;
  return (
    <Formik
      initialValues={_filterValues || { ...initialValues }}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <form onSubmit={formik.handleSubmit} className="filter_form">
            <>
              <FilterForm
                formik={formik}
                setFilterValues={handleSetFilterValues}
                filters={filters}
                category={"all"}
              />
              <div className="filter__buttons-wrapper">
                <Button
                  type="submit"
                  styles={{ backgroundColor: Colors.PINK }}
                  className="filter__submit-button"
                >
                  {loading ? (
                    <ClipLoader color="white" size={20} />
                  ) : (
                    t("BUTTONS.APPLY")
                  )}
                </Button>
                <Button
                  className="filter__reset-button"
                  onClick={() => {
                    handleSetFilterValues(null);
                    formik.resetForm({values: initialValues});
                  }}
                >
                  {t("BUTTONS.CLEAR")}
                </Button>
              </div>
            </>
          </form>
        );
      }}
    </Formik>
  );
};
export default VehicleFilter;
