import React from "react";
import { Loading } from "react-loading-dot";
import Colors from "~/consts/Colors";

const LoadingDots = () => {
  return (
    <div className="loading-dots">
      <Loading dots={3} background={Colors.PINK} />
    </div>
  );
};
export default LoadingDots;
