import React, { ChangeEventHandler, VFC } from "react";
import { OrderTitles, OrderType } from "~/consts/data/order";
import "./styles.scss";
import i18n from "i18next";

export type OrderSelectProps = {
  value: OrderType;
  onSelect: (orderType: OrderType) => void;
};

const OrderSelect: VFC<OrderSelectProps> = ({ onSelect, value }) => {
  const handleChange = (e: ChangeEventHandler<HTMLSelectElement>) => {
    onSelect(e.target.value);
  };
  return (
    <select className="order-select" onChange={handleChange} value={value}>
      {Object.entries(OrderTitles).map((value, key) => {
        return (
        <option key={`${value[0]}-${key}`} value={value[0]}>
          {i18n.t(`ORDER_OPTIONS.${value[0].toUpperCase()}`)}
        </option>
      )})}
    </select>
  );
};

export default OrderSelect;
