import React, { useEffect, useRef, useState, VFC } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const Accordion: VFC = ({ ...faq }) => {
  const [active, setActive] = useState<boolean>(false);
  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : "0px";
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };

  return (
    <div>
      <button
        className={`faq-page__question-section ${active}`}
        onClick={toggleAccordion}
      >
        <div>
          <div className="faq-page__question-align">
            <h4 className="faq-page__question-style">
              {faq.question}
            </h4>
            <FontAwesomeIcon
              icon={faPlus}
              className={
                active
                  ? `faq-page__question-icon rotate`
                  : `faq-page__question-icon`
              }
            />
          </div>
          <div
            ref={contentRef}
            className={
              active ? `faq-page__answer answer-divider` : `faq-page__answer`
            }
          >
            <p>{faq.answer}</p>
          </div>
        </div>
      </button>
    </div>
  );
};

export default Accordion;
