import React, { VFC } from "react";
import "./styles.scss";

export type ResponsiveImageProps = {
  className?: string;
  fullImageUrl: string;
  thumbnailImageUrl: string;
  alt?: string,
  title?: string,
  layout: 'list' | 'open-product'
};

const ResponsiveImage: VFC<ResponsiveImageProps> = ({
                                  className, fullImageUrl, thumbnailImageUrl, alt, title
                                }) => {

  const sizes = {
    thumbnailImage: {
      width: 1000,
      url: thumbnailImageUrl
    },
    fullSizeImage: {
      width: 3000,
      url: fullImageUrl
    }
  };

  //smalles to biggest
  const sizeOrder = [sizes.fullSizeImage, sizes.thumbnailImage];
  const fallBackSize = sizes.thumbnailImage;

  const srcSets = sizeOrder.map((size) =>{
    return `${size.url} ${size.width}w`;
  })

  const srcSet = srcSets.join(", ");

  return (
    <><img
      srcSet={ srcSet }
      src={ fallBackSize.url }
      sizes={'(max-width 1000px) 1000px, 3000px'}
      className={ `responsive-image ${ className ? className : "" }` } title={ title } alt={ alt }/>
    </>
  );
};
export default ResponsiveImage;
