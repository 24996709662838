import React, { FC, VFC } from "react";
import Logo from "~/assets/logo/main-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export type FullscreenModalHeaderProps = {
};

const FullscreenModalHeader: VFC<FullscreenModalHeaderProps> = ({
}) => {
  return (
    <div className="fullscreen-modal__header">
      <Logo />
    </div>
  );
};
export default FullscreenModalHeader;
