import React, { FC } from "react";
import "./styles.scss";

export type FullscreenModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ModalWindow: FC<FullscreenModalProps> = ({
                                                     children,
                                                     isOpen,
                                                     onClose,
                                                   }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <>
      <div className={ `modal-window__background` } onClick={onClose}>
      </div>
      <div className={ `modal-window` }>
        <div className="modal-window__content" onClick={ (e) => {
          e.stopPropagation();
        } }>
          { children }
        </div>
      </div>

    </>
  );
};

export default ModalWindow;
