import React, { useEffect, Fragment, FC } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

const ScrollToTop: FC<RouteComponentProps> = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return <Fragment>{children}</Fragment>;
};

export default withRouter(ScrollToTop);
