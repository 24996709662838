import React, { useRef, useState, VFC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {FacebookShareButton, FacebookIcon, EmailShareButton, EmailIcon, WhatsappShareButton, WhatsappIcon } from "react-share";
import { useTranslation } from "react-i18next";

const ShareModal = ({ showModal }) => {
  const currentUrl = window.location.href;
  const modalRef = useRef();
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const closeModal = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.target === modalRef.current) {
        showModal(false);
    }
  };

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
  }

   const handleCopyClick = () => {
    copyTextToClipboard(currentUrl)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="modal__container" ref={modalRef} onClick={closeModal}>
      <div className="modal__content">
        <button onClick={() => showModal(false)} className="modal__button">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2>{t("BUTTONS.SHARE")}</h2>
        <div className="modal__links">
            <FacebookShareButton url={currentUrl} title={`Share ${String(window.location)}`} >
                <FacebookIcon size={52} className="modal__social-icon"/>
            </FacebookShareButton>
            <WhatsappShareButton url={currentUrl} title={`Share ${String(window.location)}`} >
                <WhatsappIcon size={52} className="modal__social-icon"/>
            </WhatsappShareButton>
            <EmailShareButton url={currentUrl} title={`Share ${String(window.location)}`} >
                <EmailIcon size={52} className="modal__social-icon"/>
            </EmailShareButton>
        </div>
        <label htmlFor="url-input" className="modal__label">
          <input id="url-input" type="text" value={currentUrl} readOnly />
          <button
           onClick={handleCopyClick}
           className={isCopied ? 'copied' : 'copy'}
          >
            {isCopied ? 'Copied' : 'Copy'}
          </button>
        </label>
      </div>
    </div>
  );
};

export default ShareModal;
