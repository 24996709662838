import { useState, useEffect } from "react";

export const useDevice = (): string => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  if (width < 768) {
    return "isMobile";
  } else if (width < 993) {
    return "isTablet";
  } else {
    return 'isDesktop'
  }
};
