import { RootState } from "~/store";
import { Snackbar } from "./reducer";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { v4 } from "uuid";
import { cloneDeep } from "lodash";
export type Message = {
  title: string;
  message: string;
};

export const addErrorMessage = createAsyncThunk(
  "snackbar/addErrorMessage",
  ({ title, message }: Message, { dispatch }) => {
    const snackbar: Snackbar = {
      id: v4(),
      type: "error",
      title,
      message,
    };
    return snackbar;
  }
);

export const addSuccessMessage = createAsyncThunk(
  "snackbar/addSuccessMessage",
  ({ title, message }: Message, { dispatch }) => {
    const snackbar: Snackbar = {
      id: v4(),
      type: "ok",
      title,
      message,
    };
    return snackbar;
  }
);
export const removeMessage = createAsyncThunk(
  "snackbar/removeMessage",
  (id: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const snackbars = cloneDeep(state.snackbars.snackbars);

    return snackbars.filter((snackbar) => snackbar.id !== id);
  }
);
