import React, { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { RegistrationModel } from "~/api";
import LoginSection from "~/components/LoginSection";
import RegistrationForm from "~/components/RegistrationForm";
import { useDispatch } from "~/store";
import {registerUser, selectLang, setProfileType} from "~/store/slices/user";
import "./styles.scss";
import UserPlus from "../../assets/icons/user-plus.svg";
import UserTie from "../../assets/icons/user-tie.svg";
import BusinessForm from "~/components/BusinessForm";
import {useSelector} from "react-redux";

const RegistrationPage: VFC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isBusiness, setBusiness] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const lang = useSelector(selectLang);
  const handleRegistration = (user: RegistrationModel) => {
    setLoading(true);
    dispatch(setProfileType(user.type))
    dispatch(registerUser(user))
      .unwrap()
      .then((response) => {
        if (response.status === "ok") {
          history.push(`/${lang}/`);
        }
      })
      .catch((error) => {
        setError(error.message)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <main className="registration-page">
        <div className="registration-page__form">
          <h1>{t("REGISTER.HEADER")}</h1>
          <p className="registration-page__text">
            {t("HOME.REGISTER_PAGE_TITLE")}
          </p>
          <div className="registration-page__actions-block">
            <button
              className={`registration-page__action-btn ${
                !isBusiness ? "registration-page__action-btn_active" : ""
              }`}
              onClick={() => setBusiness(false)}
            >
              <UserPlus />
              <span>{t("REGISTER.TITLE")}</span>
            </button>
            <button
              className={`registration-page__action-btn ${
                isBusiness ? "registration-page__action-btn_active" : ""
              }`}
              onClick={() => setBusiness(true)}
            >
              <UserTie />
              <span>{t("REGISTER.BUSINESS")}</span>
            </button>
          </div>
          {isBusiness ? (
            <BusinessForm
                onSubmit={handleRegistration}
                type="private"
                loading={loading} />
          ) : (
            <RegistrationForm
              onSubmit={handleRegistration}
              type="private"
              loading={loading}
            />
          )}
          {error && (
            <p className="registration-page__error-message">{error}</p>
          )}
        </div>
        <div className="registration-page__login">
          <LoginSection />
        </div>
      </main>
    </>
  );
};
export default RegistrationPage;
