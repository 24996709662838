import React, {useEffect, useRef, useState, VFC} from "react";
import "./styles.scss";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import {
    changeUserPassword,
    resetSuccessMsg, selectLang,
    selectResetPasswordMsg,
} from "~/store/slices/user";
import * as Yup from "yup";
import { Field, Formik, FormikProps } from "formik";
import TextInput from "../../components/FormInputs/TextInput";
import Button from "~/components/Button";
import {useHistory, useParams} from "react-router-dom";

type RecoveryPasswordType = {
    newPassword: string;
    newPasswordConfirm: string;
}

const PasswordResetPage: VFC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<string>('ok');
    const { t } = useTranslation();
    const formikRef = useRef(null);
    const dispatch = useDispatch();
    const showSuccessMsg = useSelector(selectResetPasswordMsg);
    const { hash } = useParams<{hash: string}>();
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const history = useHistory();
    const lang = useSelector(selectLang);

    const ResetPasswordSchema = Yup.object().shape({
        newPassword: Yup.string().min(8, 'Password is too short - should be 8 chars minimum.')
            .matches(passwordRules, { message: "Please create a stronger password" })
            .required("Please enter a password"),
        newPasswordConfirm: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], "Passwords must match")
            .required("Please enter a password")
    });

    const initialValues: RecoveryPasswordType = {
        newPassword: "",
        newPasswordConfirm: "",
    };

    const handleSubmit = async (values: RecoveryPasswordType) => {
        setLoading(true);
        const {payload} = await dispatch(changeUserPassword({password: values.newPassword, hash}));
        formikRef?.current?.resetForm({values: initialValues})
        setLoading(false);
        const {status} = payload;
        setSuccess(status);
        if(status === 'ok') {
            setTimeout(() => {
                history.push(`/${lang}/login`)
            }, 3000);
        }
    };

    useEffect(() => {
        dispatch(resetSuccessMsg());
    }, []);

    return (
        <section className="start-page">
            <div className="start-page__content">
                <h1 className="start-page__title">Reset your password</h1>
                <div className="reset-password-page">
                    {showSuccessMsg && (success === 'ok' ?
                        <div className="recover-message">
                            <h4>Your password successfully changed</h4>
                        </div> :
                        <div className="recover-message-fail">
                            <h4>Something went wrong</h4>
                        </div>)
                    }
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={ResetPasswordSchema}
                        innerRef={formikRef}
                    >
                        {(formik: FormikProps<RecoveryPasswordType>) => {
                            return (<>
                                <form className="reset-form">
                                    <div>
                                        <Field
                                            type="password"
                                            name="newPassword"
                                            component={TextInput}
                                            placeholder={'Enter your password'}
                                            label={'Password*'}
                                        />
                                    </div>
                                    <div>
                                        <Field
                                            type="password"
                                            name="newPasswordConfirm"
                                            component={TextInput}
                                            placeholder={'Confirm your password'}
                                            label={'Confirm password*'}
                                        />
                                    </div>
                                    <Button
                                        onClick={formik.handleSubmit}
                                        type="submit"
                                        className="registration-form__submit"
                                        loading={loading}
                                    >
                                        Save password
                                    </Button>
                                </form>
                            </>)}}
                    </Formik>
                </div>
            </div>
        </section>
    );
};

export default PasswordResetPage;
