import React, { ReactNode, VFC } from "react";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";

export type NavItemProps = {
  icon: ReactNode;
  label: string;
  to: string;
  className?: string;
  onClick?: () => void;
};

const NavItem: VFC<NavItemProps> = ({ to, icon, label, onClick, className = "" }) => {
  return (
    <li className={`nav-item__wrapper ${className}`} onClick={onClick}>
      <Link to={to} className="nav-item">
       {icon}
        <span className="nav-item__label">{label}</span>
      </Link>
    </li>
  );
};

export default NavItem;
