import React, { VFC } from "react";
import { Field, Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import TextInput from "../FormInputs/TextInput";
import * as Yup from "yup";
import "./styles.scss";
import i18next from "i18next";
import CheckboxInput from "../FormInputs/CheckboxInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { UploadImages } from "~/components/FormInputs";
export type BusinessModelSecondStep = {
  name: string;
  lastname: string;
  company_name: string;
  phone: string;
  agree: boolean;
  type: string;
  logo: string[];
};

export type RegistrationFormProps = {
  onSubmit: (values: BusinessModelSecondStep) => void;
  loading: boolean;
  goBack: () => void;
};

const RegistrationSchema = Yup.object().shape({
  name: Yup.string().required(i18next.t("ERRORS.NAME_REQ")),
  lastname: Yup.string().required(i18next.t("ERRORS.SURNAME_REQ")),
  company_name: Yup.string().required(i18next.t("ERRORS.BUSSINESS_NAME")),
  phone: Yup.string()
    .matches(/^[0-9 -+]+$/, i18next.t("ERRORS.PHONE_INVALID"))
    .required(i18next.t("ERRORS.PHONE_REQ")),
  agree: Yup.bool().oneOf([true], i18next.t("ERRORS.AGREE_REQ")),
  logo: Yup.array(),
  // logo: Yup.array().test({
  //   test: (media) => media.length > 0,
  //   message: i18next.t("ERRORS.BUSSINESS_LOGO"),
  // }),
});
const BusinessFormSecondStep: VFC<RegistrationFormProps> = ({
  onSubmit,
  loading,
  goBack,
}) => {
  const { t } = useTranslation();
  const initialValues: BusinessModelSecondStep = {
    name: "",
    lastname: "",
    phone: "",
    company_name: "",
    agree: false,
    type: "company",
    logo: [],
  };

  const handleSubmit = (values: BusinessModelSecondStep) => {
    const body = {
      agree: values.agree,
      company_name: values.company_name,
      name: values.name,
      lastname: values.lastname,
      phone: values.phone,
      type: "company",
      logo: values?.logo[0],
    };

    onSubmit(body);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={RegistrationSchema}
    >
      {(formik: FormikProps<BusinessModelSecondStep>) => {
        return (
          <>
            <Button onClick={goBack} className="registration-form__back-button">
              <FontAwesomeIcon icon={faAngleLeft} />
              {t("common.BACK")}
            </Button>
            <form className="registration-form">
              <Field
                type="name"
                name="name"
                component={TextInput}
                placeholder={t("PLACEHOLDERS.NAME")}
                label={t("LABELS.NAME")}
              />
              <Field
                type="lastname"
                name="lastname"
                component={TextInput}
                placeholder={t("PLACEHOLDERS.SURNAME")}
                label={t("LABELS.LASTNAME")}
              />
              <Field
                type="company_name"
                name="company_name"
                component={TextInput}
                placeholder={t("PLACEHOLDERS.BUSINESS_NAME")}
                label={t("LABELS.BUSINESS_NAME")}
              />
              <Field
                type="phone"
                name="phone"
                component={TextInput}
                placeholder={t("PLACEHOLDERS.PHONE")}
                label={t("LABELS.PHONE")}
              />

              <Field
                name="logo"
                component={UploadImages}
                onChange={(files: string[]) => {
                  formik.setFieldValue(
                    "logo",
                    [...formik.values["logo"], ...files].slice(0, 1)
                  );
                }}
                hideDescription={true}
                onRemove={(index: number) => {
                  const files = [...formik.values["logo"]];
                  files.splice(index, 1);
                  formik.setFieldValue("logo", files);
                }}
                title={t("FORM.UPLOAD_LOGO")}
                maxImages={1}
              />

              <Field
                name="agree"
                component={CheckboxInput}
                label={t("REGISTER.AGREE_TERMS")}
                linkText={t("REGISTER.AGREE_TERMS_TEXTLINK")}
                link={'/terms-of-use'}
              />

              <Button
                onClick={formik.handleSubmit}
                type="submit"
                className="registration-form__submit"
                loading={loading}
              >
                {t("BUTTONS.REGISTER")}
              </Button>
            </form>
          </>
        );
      }}
    </Formik>
  );
};

export default BusinessFormSecondStep;
