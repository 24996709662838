import React, { useEffect, useState, VFC } from "react";
import Breadcrumbs from "~/components/Breadcrumbs";
import "./styles.scss";
import "../ProfilePage/styles.scss";
import { useTranslation } from "react-i18next";
import WatchlistTab from "./WatchList";
import SavedTab from "./Saved";
import { OrderType } from "~/consts/data/order";
import { useDispatch, useSelector } from "react-redux";
import { fetchSavedProduct, selectSavedProducts } from "~/store/slices/products";
import { addErrorMessage } from "~/store/slices/snackbars";
import { fetchSavedProductsOrder } from "~/api";

const SavedProductsPage: VFC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [actions, setActions] = useState<string>("ads");

  const [isFetchingData, setIsFetchingData] = useState(true);
  const [order, setOrder] = useState<OrderType>("created_desc");
  const savedProducts = useSelector(selectSavedProducts);

  useEffect(() => {
    if (Object.keys(savedProducts).length) {
      savedProductOrder();
    }  
  }, [order]);

  const savedProductOrder = async () => {
    setIsFetchingData(true);
    const token = localStorage.getItem("auth");

    try {
      const {data} = await fetchSavedProductsOrder({
        order, token})

      if (data.status === "ok") {
        dispatch(fetchSavedProduct(data.ads))
        setIsFetchingData(false);
      } else {
        addErrorMessage({
          message: "Failed",
          title: data.message,
        });
      }
    } catch (e) {
      console.log(e?.response, "error");
    }
  }

  return (
    <main className="saved-product-page">
      <Breadcrumbs className="saved-product-page__breadcrumbs" />

      <div className="profile-page__actions-switcher">
        <button
          className={`profile-page__actions-btn ${
            actions === "ads" ? "profile-page__actions-btn_active" : ""
          }`}
          onClick={() => setActions("ads")}
        >
          {t(`SAVED.ADS`)}
        </button>
        <button
          className={`profile-page__actions-btn ${
            actions === "watchlist" ? "profile-page__actions-btn_active" : ""
          }`}
          onClick={() => setActions("watchlist")}
        >
          {t(`SAVED.WATCHLIST`)}
        </button>
      </div>

      {actions === "ads" && <SavedTab  order={order}
        setOrder={setOrder} savedProducts={savedProducts}/>}
      {actions === "watchlist" && <WatchlistTab />}
    </main>
  );
};

export default SavedProductsPage;
