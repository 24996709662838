import React, { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  AccommodationProductCreateModel,
  LoginModel,
  ProductCreateModel,
  RegistrationModel,
  VehicleProductCreateModel,
} from "~/api";
import Breadcrumbs from "~/components/Breadcrumbs";
import CreateAdForm from "~/components/CreateAdForm";
import { useDispatch } from "~/store";
import { createAd } from "~/store/slices/products";
import {CreateAdFields} from '~/components/CreateAdForm/types'
import {
  selectLang,
  selectUserToken,
} from "~/store/slices/user";
import "./styles.scss";
import useNotEnglish from "~/hooks/useNotEnglish";

const CreateAdPage: VFC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectUserToken);
  const notEnglish = useNotEnglish();
  const lang = useSelector(selectLang);

  const parseAdForm = (fields: CreateAdFields) => {
    const product = {
      token,
      category: fields?.category?.id || fields?.category_id,
      sub_category: fields?.subCategory?.id,
      electronics_brand: fields.electronics_brand?.id,
      youtube: "",
      title: fields?.title || '',
      price: Number(fields?.price),
      description: fields?.description || "",
      name_lastname: `${fields?.name} ${fields?.surname}`,
      phone: fields.phone,
      city: fields.city?.id || fields?.city_id,
      media: fields?.media?.map((image) => ({ type: "image", content: image })),
    } as ProductCreateModel;
    if (fields.category.name === "Vehicle") {
      return {
        ...product,
        vehicle_brand: fields?.vehicle_brand?.id,
        vehicle_model: fields?.vehicle_model?.id,
        vehicle_year: Number(fields?.vehicle_year),
        vehicle_milage: Number(fields?.vehicle_milage),
        vehicle_fuel: fields?.vehicle_fuel.toLowerCase(),
        vehicle_gearbox: fields?.vehicle_gearbox.toLowerCase(),
      } as VehicleProductCreateModel;
    }
    if (fields?.category?.name === "Accommodation") {
      return {
        ...product,
        accommodation_square_meter: Number(fields?.accommodation_square_meter),
        accommodation_bedrooms: fields?.accommodation_bedrooms?.id,
        accommodation_sale_rent: fields?.accommodation_sale_rent?.id,
        accommodation_type: fields?.accommodation_type?.id,
      } as AccommodationProductCreateModel;
    }
    return product;
  };

  const handleSubmit = (fields: CreateAdFields) => {
    setLoading(true);

    dispatch(createAd(parseAdForm(fields)))
      .unwrap()
      .then((response) => {
        if (response.status === "ok") {
          history.push(`/${lang}/thank-you-page`);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <main className="create-ad-page">
      <Breadcrumbs />
      <div className="create-ad-page__form">
        <CreateAdForm onSubmit={handleSubmit} submitLoading={loading}/>
      </div>
    </main>
  );
};
export default CreateAdPage;
