import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldProps } from "formik";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { toBase64 } from "~/helpers";
import "./styles.scss";

export type UploadImagesProps = {
  maxImages: number;
  title: string;
  description: string;
  onChange: (images: string[]) => void;
  onRemove: (index: number) => void;
  hideDescription?: boolean;
} & FieldProps<string[]>;

const UploadImages: FC<UploadImagesProps> = ({
                                               maxImages,
                                               title,
                                               description,
                                               onChange,
                                               onRemove,
                                               field,
                                               form,
                                               hideDescription
                                             }) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [pickedFile, setPickedFile] = useState<File>();
  const [pickedFileUrl, setPickedFileUrl] = useState("");
  const [fieldList, setFieldList] = useState([]);

  const handleChange = (files: FileList) => {
    Object.entries(files).forEach((element) => {
      const file = element.filter((file, key: number) => {
        return key === 1;
      });
      setPickedFile(file[0]?.name);
      fieldList.push(file);
      setFieldList(fieldList);
    });
    setLoading(true);
    Promise.all(
      Array.from(files)
        .slice(0, maxImages)
        .map((item) => {
          const imageInBase64 = toBase64(item);
          return imageInBase64
        })
    )
      .then((base64Files) => {
        onChange(base64Files);
        setPickedFileUrl(base64Files[0]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="upload-image">
      <span className="upload-image__title">{ title }</span>
      { !hideDescription ? (
        <div className="upload-image__description">
          { field.value?.length || 0 }/{ maxImages } { description }
        </div>
      ) : null }

      <label className="upload-image__label">
        <div className="upload-image__upload-text">
          <p>
            { t("FORM.DRAG_FILE") }{ " " }
            <span className="upload-image__upload-text--highlighted">
              { t("FORM.UPLOAD_TEXT") }
            </span>
          </p>
          <p>{ t("FORM.RECOMMENDED_FORMAT") }</p>
          <p>{ t("FORM.MAX_SIZE") }</p>
        </div>
        <input
          type="file"
          name={ field.name }
          multiple
          accept="image/jpg, image/jpeg, image/png, image/bmp"
          className="upload-image__input"
          onChange={ (e) => {
            handleChange(e.currentTarget.files);
          } }
        />
      </label>
      <div className="upload-image__thumbnails">
        { field.value?.map((src, key) => {
          return (
            <div key={ key } className="upload-image__image-block">
              <div className="upload-image__thumbnail-wrapper">
                <img
                  src={ src }
                  className="upload-image__thumbnail"
                />
              </div>
              <div>
                { hideDescription ? (
                  <p className="upload-image__file-name">{ pickedFile }</p>
                ) : null }
                <button
                  onClick={ () => {
                    onRemove(key);
                  } }
                  type="button"
                  className="upload-image__delete-button">

                  <FontAwesomeIcon icon={ faTrash }/>

                  { t("FORM.REMOVE") }
                </button>
              </div>
            </div>
          )
        }) }
      </div>
      { form.touched[field.name] && form.errors[field.name] && (
        <div className="upload-image__error-message">
          { form.errors[field.name] }
        </div>
      ) }
    </div>
  );
};
export default UploadImages;
