import React, { VFC } from "react";
import Breadcrumbs from "~/components/Breadcrumbs";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import useNotEnglish from "~/hooks/useNotEnglish";

const HowAppWorksPage: VFC = () => {
  const { t } = useTranslation();
  const notEnglish = useNotEnglish();

  return (
    <main className="how-app-works-page">
      <Breadcrumbs className="how-app-works-page__breadcrumbs" />

      <div className="how-app-works-page__title">
        <h1 className="how-app-works-page__text">{t("FOOTER.LINKS.HOWAPPWORKS")}</h1>
      </div>

      <div className="how-app-works-page__content">
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Omnis
          asperiores fugit quam sunt accusamus sequi quo aspernatur accusantium
          alias repellendus amet modi nobis minus, laudantium ullam facilis.
          Nihil, alias consequuntur suscipit recusandae dignissimos, modi
          accusamus expedita vel nostrum enim animi natus. Enim modi et adipisci
          est sequi officiis consequatur ullam.
          <br />
          <br />
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Atque,
          ratione? Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          Ullam, incidunt deserunt in sit harum maiores neque excepturi hic
          ipsam eveniet magni sunt iusto nam totam perferendis, aspernatur
          eaque, dolorem officia.
          <br />
          <br />
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quos libero
          perspiciatis sit aperiam porro officiis velit molestiae repellat odio
          reiciendis optio harum, nisi similique, in quis illo suscipit illum,
          pariatur sunt fuga eius assumenda sapiente! Possimus nobis sit eum
          blanditiis! Lorem ipsum dolor sit, amet consectetur adipisicing elit.
          Atque, ratione? Lorem ipsum, dolor sit amet consectetur adipisicing
          elit. Ullam, incidunt deserunt in sit harum maiores neque excepturi
          hic ipsam eveniet magni sunt iusto nam totam perferendis, aspernatur
          eaque, dolorem officia.
        </p>
      </div>
    </main>
  );
};

export default HowAppWorksPage;
