import React, { FC, useEffect, useState } from "react";
import useMediaQuery from "~/hooks/useMediaQuery";
import FullscreenModal from "../FullscreenModal";
import FilterItem from "./FilterItem";
import IconLog from "../../assets/icons/locationMark.svg";
import AccomodationMark from "../../assets/icons/accomodation-locationMark.svg";

export type DropdownProps = {
  id: number | string;
  label: string;
  value?: string;
  placeholder?: string;
  name?: string;
  category?: string;
};

const Dropdown: FC<DropdownProps> = ({
  children,
  name,
  label,
  value,
  placeholder,
  category,
  id,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isTablet = useMediaQuery("tablet>");

  useEffect(() => {
    setIsModalOpen(false);
  }, [value]);

  return (
    <FilterItem
      label={label}
      onClick={() => {
        setIsModalOpen(!isModalOpen);
      }}
      isOpen={isModalOpen}
      value={value}
    >
      {!isTablet ? (
        <FullscreenModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
        >
          {children}
        </FullscreenModal>
      ) : (
        <div
          className="dropdown__wrapper"
          onBlur={(event) => {
            if (!event.currentTarget.contains(event.relatedTarget)) {
              setIsModalOpen(false);
            }
          }}
        >
          <button
            type="button"
            tabIndex={0}
            className={`dropdown__button ${
              isModalOpen ? "" : "dropdown__button--closed"
            } ${name === "city" && "dropdown_location"}`}
            onClick={() => {
              setIsModalOpen(!isModalOpen);
            }}
          >
            {value ? (
              value
            ) : (
              <span className="dropdown__placeholder">{placeholder}</span>
            )}
          </button>
          {name === "city" ? (
            <div className="filter__location_logo">
              {category !== "accommodation" ? (
                <IconLog
                  className={`
                  ${category}-logo
                  `}
                />
              ) : (
                <AccomodationMark />
              )}
            </div>
          ) : (
            <div className="filter__location_logo"></div>
          )}
          <div
            className={`dropdown ${isModalOpen ? "" : "dropdown--closed"} ${
              name === "city" && "dropdown_location"
            }`}
          >
            {children}
          </div>
        </div>
      )}
    </FilterItem>
  );
};
export default Dropdown;
