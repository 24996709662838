import { Field, Formik, FormikProps } from "formik";
import { isEmpty } from "lodash";
import React, {useEffect, useRef, useState, FC} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import i18next from "i18next";
import { Category, City, SubCategory, VehicleBrand, fetchElectronicsBrands } from "~/api";
import {
  AccommodationBedroomAmount,
  ACCOMMODATION_BEDROOM_AMOUNT,
  ACCOMMODATION_SALE_RENT,
  ACCOMMODATION_TYPES,
} from "~/consts/data/accommodations";
import { VEHICLE_FUELS, VEHICLE_GEARBOXES } from "~/consts/data/vehicles";
import {
  fetchCategories, fetchCities, fetchSubCategories,
  selectCategories,
  selectCities,
  selectSubCategories,
} from "~/store/slices/categories";
import { selectUserProfile } from "~/store/slices/user";
import {
  fetchVehicleBrands, fetchVehicleModels,
  selectVehicleBrands,
  selectVehicleModels,
} from "~/store/slices/vehicles";
import Button from "../Button";
import {
  CheckboxInput,
  DropdownInput,
  TextAreaInput,
  UploadImages,
} from "../FormInputs";
import TextInput from "../FormInputs/TextInput";
import "./styles.scss";
import {
  setCreateAdProduct,
  selectCreatedAdProducts,
  selectShowPreviewForm,
  setPreviewForm,
} from "~/store/slices/products";

import { default as ProductDetailsBlock } from "../../pages/ProductDetailsPage/ProductDetails";
import { CreateAdFields } from "./types";
import { ClipLoader } from "react-spinners";
import LoadingDots from "../LoadingDots";

export type CreateAdFormProps = {
  onSubmit: (values: CreateAdFields) => void;
  submitLoading: boolean;
};

const ValidationSchema = Yup.object().shape({
  category: Yup.object()
    .nullable()
    .required(i18next.t("ERRORS.CREATE_CATEGORY_REQ")),
  title: Yup.string().required(i18next.t("ERRORS.CREATE_CATEGORY_TTLE_REQ")),
  price: Yup.number()
    .max(999999999, i18next.t("ERRORS.PRICE_MAX", {number: "9"}))
    .required(i18next.t("ERRORS.CREATE_CATEGORY_PRICE_REQ")),
  description: Yup.string().required(
    i18next.t("ERRORS.CREATE_CATEGORY_DESC_REQ")
  ),
  city: Yup.object()
    .nullable()
    .required(i18next.t("ERRORS.CREATE_CATEGORY_CITY_REQ")),
  phone: Yup.string().matches(/^[0-9 -+]+$/, i18next.t("ERRORS.PHONE_INVALID")),
  name: Yup.string().required(i18next.t("ERRORS.NAME_REQ")),
  surname: Yup.string().required(i18next.t("ERRORS.SURNAME_REQ")),
  agree: Yup.boolean().oneOf([true], i18next.t("ERRORS.AGREE_REQ")),
  media: Yup.array().test({
    test: (media) => media?.length > 0,
    message: i18next.t("ERRORS.CREATE_MEDIA_REQ"),
  }),
  vehicle_brand: Yup.object()
    .nullable()
    .when("subcategory.name", {
      is: "Cars",
      then: Yup.object()
        .nullable()
        .required(i18next.t("ERRORS.CREATE_BRAND_REQ")),
    }),
  vehicle_model: Yup.object()
    .nullable()
    .when("subcategory.name", {
      is: "Cars",
      then: Yup.object()
        .nullable()
        .required(i18next.t("ERRORS.CREATE_MODEL_REQ")),
    }),
  vehicle_year: Yup.string().when("category.name", {
    is: "Vehicle",
    then: Yup.string().required(i18next.t("ERRORS.CREATE_YEAR")),
  }),
  vehicle_milage: Yup.string().when("category.name", {
    is: "Vehicle",
    then: Yup.string().required(i18next.t("ERRORS.CREATE_MILLAGE")),
  }),
  vehicle_gearbox: Yup.string()
    .nullable()
    .when("category.name", {
      is: "Vehicle",
      then: Yup.string()
        .nullable()
        .required(i18next.t("ERRORS.CREATE_GEARBOX_REQ")),
    }),
  vehicle_fuel: Yup.string()
    .nullable()
    .when("category.name", {
      is: "Vehicle",
      then: Yup.string()
        .nullable()
        .required(i18next.t("ERRORS.CREATE_FUEL_REQ")),
    }),
  accommodation_square_meter: Yup.string().when("category.name", {
    is: "Accommodation",
    then: Yup.string().required(i18next.t("ERRORS.CREATE_ACCOMMODATION_AREA")),
  }),
  accommodation_bedrooms: Yup.object()
    .nullable()
    .when("category.name", {
      is: "Accommodation",
      then: Yup.object()
        .nullable()
        .required(i18next.t("ERRORS.CREATE_ACCOMMODATION_AREA")),
    }),
  accommodation_sale_rent: Yup.object()
    .nullable()
    .when("category.name", {
      is: "Accommodation",
      then: Yup.object()
        .nullable()
        .required(i18next.t("ERRORS.CREATE_ACCOMMODATION_SALERENT")),
    }),
  accommodation_type: Yup.object()
    .nullable()
    .when("category.name", {
      is: "Accommodation",
      then: Yup.object()
        .nullable()
        .required(i18next.t("ERRORS.CREATE_ACCOMMODATION_TYPE")),
    }),
  electronics_brand: Yup.object().nullable(),
});

const CreateAdForm: FC<CreateAdFormProps> = ({onSubmit, submitLoading}) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const subCategories = useSelector(selectSubCategories);
  const cities = useSelector(selectCities);
  const user = useSelector(selectUserProfile);
  const vehicleBrands = useSelector(selectVehicleBrands);
  const vehicleModels = useSelector(selectVehicleModels);
  // const isLoading = isEmpty(categories) || isEmpty(subCategories) || isEmpty(cities);
  const isLoading = isEmpty(categories);

  const showPreviewForm = useSelector(selectShowPreviewForm);
  const createdAdProducts = useSelector(selectCreatedAdProducts);
  const [electronicsBrands, setElectronicsBrands] = useState<{ id: number; name: string }[] | []>([]);
  const [loading, setLoading] = useState(false);
  const formikRef = useRef();
  const languagePrefix = i18n.language === 'arab' ? '_arabic' : i18n.language === 'kur' ? '_kurdish' : '';

  useEffect(() => {
    if (isEmpty(categories)) {
      dispatch(fetchCategories());
    }
    if (isEmpty(cities)) {
      dispatch(fetchCities());
    }
  }, []);

  const initialValues: CreateAdFields = {
    category: null,
    subCategory: null,
    title: "",
    price: "",
    description: "",
    name: user?.name,
    surname: user?.lastname,
    phone: user?.phone,
    city: null,
    media: [],
    vehicle_brand: null,
    vehicle_model: null,
    vehicle_year: "",
    vehicle_milage: "",
    vehicle_fuel: null,
    vehicle_gearbox: null,
    accommodation_square_meter: "",
    accommodation_bedrooms: null,
    accommodation_sale_rent: null,
    accommodation_type: null,
    electronics_brand: null,
    agree: false,
  };

  const previewClick = (formik: FormikProps<any>) => {
    setLoading(true);
    const product: CreateAdFields = {
      ...formik.values,
      user_id: user?.id,
      id: 0,
      price: Number(formik.values.price),
      status: "active",
      sub_category: formik.values.subCategory?.name,
      sub_category_id: formik.values.subCategory?.id,
      category_id: formik.values.category.id,
      category: formik.values.category,
      youtube: "",
      name_lastname: formik.values.name + " " + formik.values.surname,
      city_id: formik.values.city.id,
      city: formik.values.city.name,
      media: formik.values.media,
      ad_views: 0,
      saved_ad: false,
      extendable: false,
    };

    dispatch(setCreateAdProduct(product));
    dispatch(setPreviewForm(!showPreviewForm))
    setLoading(false);
  };

  const handleCategoryChange = (formik: FormikProps<CreateAdFields>) => {
    if (formik.values["subCategory"]) {
      formik.setFieldValue("subCategory", null);
    }
  };

  const getBrands = async (id: number): Promise<void> => {
    try {
      const {data: {data}} = await fetchElectronicsBrands(id);
      setElectronicsBrands(data);
    } catch (e) {
      console.log('e: ', e);
    }
  }

  const handleVehicleBrandChange = (
    formik: FormikProps<CreateAdFields>,
    brand: VehicleBrand
  ) => {
    dispatch(fetchVehicleModels(brand.id));
    if (
      formik.values["vehicle_model"] &&
      brand.id !== formik.values["vehicle_model"]?.parentId
    ) {
      // Clear field
      formik.setFieldValue("vehicle_model", null);
    }
  };

  if (isLoading) return <LoadingDots/>;
  const maxImages = user?.type === 'company' ? 20 : 5;

  return (
    <>
      <Formik
        initialValues={ initialValues }
        onSubmit={ onSubmit }
        enableReinitialize={ true }
        validationSchema={ ValidationSchema }
        innerRef={ formikRef }
      >
        { (formik) => {
          return (
            <>
              { !showPreviewForm ? (
                <form onSubmit={ formik.handleSubmit } className="create-ad-form">
                  <div className="create-ad-form__card">
                    <h1 className="create-ad-form__title">
                      { t("CREATE_AD.HEADER") }
                    </h1>
                    <div className="create-ad-form__section">
                      <h2 className="create-ad-form__heading">
                        { t("CREATE_AD.GENERAL_INFO") }
                      </h2>
                      <Field
                        name="title"
                        component={ TextInput }
                        label={ t("LABELS.TITLE") }
                        placeholder={ t("PLACEHOLDERS.TITLE") }
                      />
                      <Field
                        name="price"
                        component={ TextInput }
                        label={ t("LABELS.PRICE") }
                        type="number"
                        placeholder={ t("PLACEHOLDERS.PRICE") }
                      />
                      <Field
                        name="description"
                        type="textarea"
                        component={ TextAreaInput }
                        label={ t("LABELS.DESCRIPTION") }
                        placeholder={ t("PLACEHOLDERS.ENTER_DESCRIPTION") }
                      />
                      <Field
                        name="category"
                        component={ DropdownInput }
                        label={ t("LABELS.CATEGORY") }
                        items={ [...categories] }
                        filterSelected
                        getLabel={ (value: Category) => value.name }
                        placeholder={ t("PLACEHOLDERS.SELECT_CATEGORY") }
                        onChange={ (value: Category) => {
                          handleCategoryChange(formik);
                          dispatch(fetchSubCategories(value.id));
                        } }
                      />
                      { formik.values["category"] !== null &&
                      subCategories.filter(
                        (subCategory) =>
                          subCategory.parentId === formik.values["category"]?.id
                      )?.length > 0 ? (
                        <Field
                          name="subCategory"
                          component={ DropdownInput }
                          label={ t("LABELS.SUB_CATEGORY") }
                          filterSelected
                          items={ [
                            ...subCategories.filter(
                              (subCategory) =>
                                subCategory.parentId ===
                                formik.values["category"]?.id
                            ),
                          ] }
                          getLabel={ (value: SubCategory) => value.name }
                          placeholder={ t("PLACEHOLDERS.SELECT_SUB_CATEGORY") }
                          onChange={ async (e: any) => {
                            if (e.name !== 'Cars') {
                              formik.setFieldValue('vehicle_brand', null);
                              formik.setFieldValue('vehicle_model', null);
                              if (formik.values["category"]?.name === 'Electronics') {
                                await getBrands(e.id);
                              } else {
                                formik.setFieldValue('electronics_brand', null);
                              }
                              return false;
                            }

                            if (isEmpty(vehicleBrands)) dispatch(fetchVehicleBrands());
                          } }
                        />
                      ) : null }
                      { formik.values["category"]?.name === "Electronics" ? (
                        <>
                          <Field
                            name="electronics_brand"
                            component={ DropdownInput }
                            label={ t("LABELS.BRAND") }
                            items={ electronicsBrands }
                            filterSelected
                            getLabel={ (value: VehicleBrand) => value.name }
                            placeholder={ t(
                              "PLACEHOLDERS.SELECT_ELECTRONICS_BRAND"
                            ) }
                          />
                        </>
                      ) : null
                      }

                      { formik.values["category"]?.name === "Vehicle" ? (
                        <>
                          { formik.values.subCategory?.name === "Cars" ? (
                            <>
                              <Field
                                name="vehicle_brand"
                                component={ DropdownInput }
                                label={ t("LABELS.VEHICLE_BRAND") }
                                items={ [...vehicleBrands] }
                                filterSelected
                                getLabel={ (value: VehicleBrand) => value.name }
                                placeholder={ t("PLACEHOLDERS.SELECT_VEHICLE_BRAND") }
                                onChange={ (newValue: VehicleBrand) => {
                                  handleVehicleBrandChange(formik, newValue);
                                } }
                              />
                              { formik.values["vehicle_brand"] ? (
                                <Field
                                  name="vehicle_model"
                                  component={ DropdownInput }
                                  label={ t("LABELS.VEHICLE_MODELS") }
                                  items={ [
                                    ...vehicleModels.filter(
                                      (model) =>
                                        model.parentId ===
                                        formik.values["vehicle_brand"]?.id
                                    ),
                                  ] }
                                  filterSelected
                                  getLabel={ (value: VehicleBrand) => value.name }
                                  placeholder={ t(
                                    "PLACEHOLDERS.SELECT_VEHICLE_MODEL"
                                  ) }
                                />
                              ) : null }
                            </>
                          ) : null }
                          <Field
                            name="vehicle_year"
                            component={ TextInput }
                            label={ t("LABELS.VEHICLE_YEAR") }
                            type="number"
                            placeholder={ t("PLACEHOLDERS.ENTER_VEHICLE_YEAR") }
                          />
                          <Field
                            name="vehicle_milage"
                            component={ TextInput }
                            label={ t("LABELS.VEHICLE_MILLAGE") }
                            type="number"
                            placeholder={ t(
                              "PLACEHOLDERS.ENTER_VEHICLE_MILLAGE"
                            ) }
                          />
                          <Field
                            name="vehicle_gearbox"
                            component={ DropdownInput }
                            label={ t("LABELS.VEHICLE_GEARBOX") }
                            items={ VEHICLE_GEARBOXES?.map(item => item.charAt(0).toUpperCase() + item.slice(1)) }
                            getLabel={ (item: string) => item }
                            type="number"
                            placeholder={ t(
                              "PLACEHOLDERS.SELECT_VEHICLE_GEARBOX"
                            ) }
                          />
                          <Field
                            name="vehicle_fuel"
                            component={ DropdownInput }
                            items={ VEHICLE_FUELS?.map(item => item.charAt(0).toUpperCase() + item.slice(1)) }
                            getLabel={ (item: string) => item }
                            label={ t("LABELS.VEHICLE_FUEL") }
                            type="number"
                            placeholder={ t("PLACEHOLDERS.SELECT_VEHICLE_FUEL") }
                          />
                        </>
                      ) : null }
                      { formik.values["category"]?.name === "Accommodation" ? (
                        <>
                          <Field
                            name="accommodation_square_meter"
                            component={ TextInput }
                            label={ t("LABELS.SQUARE_METER") }
                            type="number"
                            placeholder={ t(
                              "PLACEHOLDERS.ENTER_ACCOMMODATION_AREA"
                            ) }
                          />
                          <Field
                            name="accommodation_bedrooms"
                            component={ DropdownInput }
                            label={ t("LABELS.ACCOMMODATION_BEDROOM_AMOUNT") }
                            items={ [...ACCOMMODATION_BEDROOM_AMOUNT] }
                            getLabel={ (item: AccommodationBedroomAmount) =>
                              item.name
                            }
                            placeholder={ t(
                              "PLACEHOLDERS.SELECT_BEDROOM_AMOUNT"
                            ) }
                          />
                          <Field
                            name="accommodation_sale_rent"
                            component={ DropdownInput }
                            items={ [...ACCOMMODATION_SALE_RENT] }
                            getLabel={ (item: AccommodationBedroomAmount) =>
                              item.name
                            }
                            label={ t("LABELS.ACCOMMODATION_SALE_RENT") }
                            placeholder={ t(
                              "PLACEHOLDERS.SELECT_ACCOMMODATION_SALE_RENT"
                            ) }
                          />
                          <Field
                            name="accommodation_type"
                            component={ DropdownInput }
                            items={ [...ACCOMMODATION_TYPES] }
                            getLabel={ (item: AccommodationBedroomAmount) =>
                              item.name
                            }
                            label={ t("LABELS.ACCOMMODATION_TYPE") }
                            placeholder={ t(
                              "PLACEHOLDERS.SELECT_ACCOMMODATION_TYPE"
                            ) }
                          />
                        </>
                      ) : null }
                    </div>
                    <div className="create-ad-form__section">
                      <h2 className="create-ad-form__heading">
                        { t("CREATE_AD.OTHER_INFO") }
                      </h2>
                      <Field
                        name="name"
                        component={ TextInput }
                        label={ t("LABELS.NAME") }
                        placeholder={ t("PLACEHOLDERS.NAME") }
                      />
                      <Field
                        name="surname"
                        component={ TextInput }
                        label={ t("LABELS.SURNAME") }
                        placeholder={ t("PLACEHOLDERS.SURNAME") }
                      />
                      <Field
                        name="phone"
                        component={ TextInput }
                        label={ t("LABELS.PHONE") }
                        placeholder={ t("PLACEHOLDERS.PHONE") }
                      />
                      <Field
                        name="city"
                        component={ DropdownInput }
                        label={ t("LABELS.CITY") }
                        items={ [...cities] }
                        getLabel={ (city: City) => city[`name${ languagePrefix }`] }
                        filterSelected
                        placeholder={ t("PLACEHOLDERS.SELECT_CITY") }
                      />
                      <Field
                        name="media"
                        component={ UploadImages }
                        onChange={ (files: string[]) => {
                          formik.setFieldValue(
                            "media",
                            [...formik.values["media"], ...files].slice(0, maxImages)
                          );
                        } }
                        onRemove={ (index: number) => {
                          const files = [...formik.values["media"]];
                          files.splice(index, 1);
                          formik.setFieldValue("media", files);
                        } }
                        title={ t("FORM.UPLOAD_PHOTO_TITLE") }
                        maxImages={ maxImages }
                        description={ t("FORM.PHOTO_DESCRIPTION") }
                      />
                    </div>
                  </div>
                  <Field
                    name="agree"
                    component={ CheckboxInput }
                    label={ t("REGISTER.AGREE_TERMS") }
                    className="create-ad-form__tac"
                    linkText={ t("REGISTER.AGREE_TERMS_TEXTLINK") }
                    link={ '/terms-of-use' }
                  />
                  <div className="create-ad-form__buttons">
                    <Button type="submit">
                      { submitLoading ? (
                        <ClipLoader color="white" size={ 20 }/>
                      ) : (
                        t("BUTTONS.PUBLISH")
                      ) }
                    </Button>

                    { !Object.values(formik.errors)?.length &&
                      !!Object.values(formik.touched)?.length && (
                        <Button
                          type="button"
                          onClick={ () => {
                            previewClick(formik);
                          } }
                        >
                          { loading ? (
                            <ClipLoader color="white" size={ 20 }/>
                          ) : (
                            t("FORM.SHOW_PREVIEW")
                          ) }
                        </Button>
                      ) }
                  </div>
                </form>
              ) : (
                <div className="product-details-page">
                  <ProductDetailsBlock
                    previewMark={ true }
                    product={ createdAdProducts }
                    category={ formik.values.category }
                    onSubmit={ onSubmit }
                    submitLoading={ submitLoading }
                  />
                </div>
              ) }
            </>
          );
        } }
      </Formik>
    </>
  );
};
export default CreateAdForm;
