import React, { useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import {
  AccommodationProductDetails,
  Category,
  ElectronicsProductDetails,
  OtherProductDetails,
  ProductDetailsType,
  VehicleBrand,
  VehicleProductDetails,
  fetchSavedDelete,
  fetchSavedAdd,
} from "~/api";
import i18n from "i18next";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { store, useDispatch } from "~/store";
import {
  fetchAllVehicleModels,
  fetchVehicleBrands,
} from "~/store/slices/vehicles";
import Button from "../Button";
import moment from "moment";
import Clock from "../../assets/icons/clock.svg";
import { CreateAdFields } from "../CreateAdForm/types";
import {
  selectCreatedAdProducts,
  selectProductDetails,
  selectShowPreviewForm,
  setPreviewForm,
} from "~/store/slices/products";
import {selectLang, selectUserToken} from "~/store/slices/user/selectors";
import LoadingDots from "../LoadingDots";
import { ClipLoader } from "react-spinners";
import { useHistory } from "react-router-dom";
import ShareModal from "./ShareModal";

export type ProductDetailsProps = {
  product: ProductDetailsType;
  category: Category;
  previewMark?: boolean;
  onClick?: () => void;
  onSubmit?: (values: CreateAdFields) => void;
  submitLoading?: boolean;
};

export type PropertyField = {
  vehicles: (keyof VehicleProductDetails)[];
  accommodations: (keyof AccommodationProductDetails)[];
  electronics: (keyof ElectronicsProductDetails)[];
  other: (keyof OtherProductDetails)[];
  all: (keyof ProductDetailsType)[];
};

const selectModels = () => store.getState().vehicles.models;
const selectBrands = () => store.getState().vehicles.brands;

const ProductDetails: VFC<ProductDetailsProps> = ({
  product,
  category,
  previewMark,
  onSubmit,
  submitLoading,
}) => {
  const [saved, setSaved] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const models = useSelector(selectModels);
  const brands = useSelector(selectBrands);
  const data = moment()
    .locale(
      i18n.language === "arab"
        ? "ar-sa"
        : i18n.language === "kur"
        ? "ku"
        : "en-gb"
    )
    .calendar();
  const token = useSelector(selectUserToken);
  const createdAdProducts = useSelector(selectCreatedAdProducts);
  const showPreviewForm = useSelector(selectShowPreviewForm);
  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);
  const lang = useSelector(selectLang);

  const openModal = () => {
    setShowModal(true);
  };

  const productSaved = async (): Promise<void> => {
    try {
      const { data } = saved
        ? await fetchSavedDelete({ ad_id: product.id, token })
        : await fetchSavedAdd({ ad_id: product.id, token });
      if (data.status === "ok") {
        setSaved(!saved);
      } else {
        setModalStatus(data.status, data.message);
      }
    } catch (e) {
      console.log(e?.response, "error");
    }
  };

  useEffect(() => {
    // new Promise<VehicleBrand[]>((res, rej) => {
    //   if (brands?.length < 1) {
    //     dispatch(fetchVehicleBrands())
    //       .unwrap()
    //       .then((response) => {
    //         res(response);
    //       })
    //       .catch((error) => {
    //         rej(error);
    //       });
    //   } else {
    //     res(brands);
    //   }
    // }).then((brands) => {
    //   if (models?.length < 1) {
    //     dispatch(fetchAllVehicleModels(brands));
    //   }
    // });

    setSaved(product.saved_ad);
  }, []);

  // const isLoading =
  //   !category || !product || brands?.length < 1 || models?.length < 1;
  const isLoading = !product;
  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <div className="product-details">
      <ul className="product-details__list">
        {Object.keys(product)?.map(
          (property, index) =>
            i18n.exists(`DESCRIPTION_DATA.${property.toLocaleUpperCase()}`) &&
            !!product[property] && (
              <li className="product-details__list-item" key={index}>
                <span className="product-details__property-label">
                  {t(`DESCRIPTION_DATA.${property.toLocaleUpperCase()}`)}
                </span>
                <span className="product-details__property-value">
                  {property === "name"
                    ? product.name_lastname
                    : product[property].hasOwnProperty('name') ? product[property]['name'] : product[property]}
                </span>
              </li>
            )
        )}
      </ul>
      <div className="product-details__content">
        <div className="product-details__date-section">
          <Clock className="product-details__clock" />
          {!!data && <span className="product-details__date">{data}</span>}
        </div>

        <button onClick={openModal} className="product-details__share">{t("BUTTONS.SHARE")}</button>
        {showModal ? <ShareModal showModal={setShowModal} /> : null}

        <span className="product-details__price">{"$" + product.price}</span>
        <div className="product-details__previewBtn">
          {previewMark && <Button
           onClick={() => onSubmit(createdAdProducts)}
           className={"product-details__create"}>
            {submitLoading ? (<ClipLoader color="white" size={20} />) : (t(`BUTTONS.PUBLISH`))}
          </Button>}

          <Button
            onClick={() => history.push({pathname: `/${lang}/ad/${product.id}/seller-profile`})}
            className={"product-details__contact"}
          >{t(`BUTTONS.CONTACT_SELLER`)}</Button>

          <Button
            className={
              saved
                ? "product-details__save__active"
                : !previewMark
                ? "product-details__save"
                : "product-details__edit"
            }
            onClick={() =>
              previewMark
                ? dispatch(setPreviewForm(!showPreviewForm))
                : productSaved()
            }
          >
            <p>{t(!previewMark ? "BUTTONS.SAVE" : "BUTTONS.EDIT_AD")}</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
function setModalStatus(status: any, message: any) {
  throw new Error("Function not implemented.");
}
