export const VEHICLE_FUELS = [
  "petrol",
  "diesel",
  "hybrid",
  "electric",
] as const;
export type VehicleFuel = typeof VEHICLE_FUELS[number];

export const VEHICLE_GEARBOXES = ["manual", "automatic"] as const;
export type VehicleGearbox = typeof VEHICLE_FUELS[number];
