export const routes = Object.freeze({
  productPage: ":lang/products/:category/:productId",
  listPage: ":lang/products/:category",
  register: ":lang/register",
  login: ":lang/login",
  noResultPage: ":lang/no-result-page",
  thankYouPage: ":lang/thank-you-page",
  createAd: ":lang/create-ad",
  profilePage: ":lang/profile",
  savedProductPage: ":lang/saved",
  faqPage: ":lang/faq",
  aboutPage: ":lang/about-us",
  termsofusePage: ":lang/terms-of-use",
  privacyPage: ":lang/privacy-policy",
  cookiesPage: ":lang/cookies",
  howAppWorksPage: ":lang/how-app-works",
  sellerProfile: ":lang/ad/:adId/seller-profile",
  passwordRecovery: ":lang/password-recovery",
  passwordReset: ":lang/reset-password/:hash?",
});
