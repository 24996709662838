import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import PaginationItem, { PaginationItemProps } from "./PaginationItem";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";
import { useSelector } from "react-redux";
import { selectLang } from "~/store/slices/user";

export type PaginationProps = {
  className?: string;
  setPage?: (selectedPage: number) => void;
  pageCount: number;
  itemsOnCurrentPage: number;
  itemCount: number;
  selectedPage: number;
  color?: string;
  pageSize: number;
};

const Pagination: VFC<PaginationProps> = ({
  className = "",
  setPage,
  pageCount,
  itemsOnCurrentPage,
  itemCount,
  selectedPage,
  color,
  pageSize,
}) => {
  const { t } = useTranslation();
  const lang = useSelector(selectLang);
  const pagesShownBeforeSelected = 2;
  const pagesShownAfterSelected = 3;
  const showFirstPage = selectedPage - pagesShownBeforeSelected > 0;
  const showLastPage = selectedPage < pageCount - 1 - pagesShownAfterSelected;
  const maxPages =
    1 +
    pagesShownAfterSelected +
    pagesShownAfterSelected +
    (showFirstPage ? -1 : 0) +
    (showLastPage ? -1 : 0);
  let paginationItems: PaginationItemProps[] = [];
  const from = 1 + selectedPage * pageSize;
  const to = (1 + selectedPage) * pageSize;
  if (maxPages < pageCount) {
    const startPageIndex =
      selectedPage - pagesShownBeforeSelected < 0
        ? 0
        : selectedPage - pagesShownBeforeSelected;

    const pageItems: PaginationItemProps[] = [...Array(maxPages)]
      .map((_, key) =>
        startPageIndex + key < pageCount
          ? {
              onClick: () => setPage(startPageIndex + key),
              value: startPageIndex + key + 1,
              selected: startPageIndex + key === selectedPage,
            }
          : null
      )
      .filter((item) => item !== null);
    const firstPage = showFirstPage
      ? [
          {
            onClick: () => setPage(0),
            value: 1,
            selected: 0 === selectedPage,
          },
          {
            value: "...",
          },
        ]
      : [];
    const lastPage = showLastPage
      ? [
          {
            value: "...",
          },
          {
            onClick: () => setPage(pageCount - 1),
            value: pageCount,
            selected: pageCount - 1 === selectedPage,
          },
        ]
      : [];
    paginationItems = [...firstPage, ...pageItems, ...lastPage];
  } else {
    const pageItems: PaginationItemProps[] = [...Array(pageCount)].map(
      (_, key) => ({
        onClick: () => setPage(key),
        value: key + 1,
        selected: key === selectedPage,
      })
    );
    paginationItems = [...pageItems];
  }

  const goBack = () => {
    const newValue = selectedPage - 1 >= 0 ? selectedPage - 1 : 0;
    setPage(newValue);
  };
  const goForward = () => {
    const newValue =
      selectedPage + 1 < pageCount ? selectedPage + 1 : pageCount - 1;
    setPage(newValue);
  };
  return (
    pageCount > 1 && (
      <div className={`pagination ${className}`}>
        <span className="pagination__count">
          {itemsOnCurrentPage > 1
            ? `${from} - 
          ${to > itemCount ? itemCount : to} `
            : `${itemCount} `}
          {t("SAVED.PAGINATION.OFTOTAL")} {itemCount}{" "}
          {t("SAVED.PAGINATION.ADS")}
        </span>
        <ul className="pagination__items">
          <PaginationItem onClick={goBack} value={lang === "en" || lang === null ? <FontAwesomeIcon icon={faAngleLeft} /> : <FontAwesomeIcon icon={faAngleRight} />} />
          {paginationItems.map((paginationItem, key) => (
            <PaginationItem {...paginationItem} key={key} color={color} />
          ))}
          <PaginationItem onClick={goForward} value={lang === "en" || lang === null ? <FontAwesomeIcon icon={faAngleRight} /> : <FontAwesomeIcon icon={faAngleLeft} />} />
        </ul>
      </div>
    )
  );
};
export default Pagination;
