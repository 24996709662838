import { RootState, store } from "~/store";

export const selectUserProfile = (store: RootState) => store.user.user;
export const selectUserAds = (store: RootState) => store.user.ads;

export const selectUserToken = (store: RootState) => store.user.token;

export const selectLang = (store: RootState) => store.user.lang;

export const selectUserType = (store: RootState) =>
  store.user.profilePasswordData.type;

export const useAuthenticated = (store: RootState) => !!store.user.token;

export const selectUserProfileSetting = (store: RootState) =>
  store.user.profilePasswordData;

export const selectSocialLogin = (store: RootState) => store.user.socialLogin;

export const selectSellerData = (store: RootState) => store.user.sellerData;
export const selectSellerProfile = (store: RootState) => store.user.sellerData;
export const selectSellerProfileLoading = (store: RootState) => store.user.sellerDataLoading;
export const selectSellerProfileError = (store: RootState) => store.user.sellerDataError;
export const selectResetPasswordShow = (store: RootState) => {
    return store.user.resetPasswordShowMsg;
}
export const selectResetPasswordMsg = (store: RootState) => {
    return store.user.recoveryShowMsg;
}

