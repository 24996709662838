import React from "react";
import { useSelector } from "react-redux";
import { selectUserProfile } from "../../store/slices/user/selectors";
import { useTranslation } from "react-i18next";

interface Account {
  myProfile?: boolean;
  sellerData?: {};
}

const AccountData = ({
  myProfile = false,
  sellerData,
}: Account): JSX.Element | null => {
  const { t } = useTranslation();

  const userProfileData = useSelector(selectUserProfile);

  return (
    <div>
      {userProfileData && (
        <div>
          <p className="text-input__label-text">{t("LABELS.EMAIL")}</p>
          <p>{userProfileData.email}</p>
      </div>
      )}
    </div>
  );
};

export default AccountData;
