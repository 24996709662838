import moment from 'moment/moment';
import i18n from '~/i18n/config';
import ProductCard from '~/components/ProductCard';
import React from 'react';
import { Media, Product } from '~/api';

const ProductCardWrap = (product:Product) => {
  const media:Media[] = product?.media ? product.media : [];

  return (
    <ProductCard

      {...product}
      logo={product?.profile_logo}
      moreAds
      className="start-page__product-card"
      key={product?.id}
      description={product?.info}
      location={product?.city}
      date={moment(product?.created)
        .locale(
          i18n.language === "arab"
            ? "ar-sa"
            : i18n.language === "kur"
              ? "ku"
              : "en-gb"
        )
        .format("ddd HH:mm")}
      priceTag={product?.price?.toString()}
    />
  )
};
export default ProductCardWrap;
