import React, { VFC } from "react";

export type YoutubeEmbedProps = {
  embedId: string | false;
  className?: string;
};

const YoutubeEmbed: VFC<YoutubeEmbedProps> = ({ embedId, className = "" }) => (
  <div className={className}>
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
);

export default YoutubeEmbed;
