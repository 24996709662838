import { ALL_CATEGORIES } from "./../consts/data/categories";
import api, { ResponseWrapper } from "./axios";

export const CATEGORIES = [
  "Vehicle",
  "Accommodation",
  "Electronics",
  "Other",
  "All",
] as const;

export type Category = {
  id: number;
  name: typeof CATEGORIES[number];
  slug?: typeof ALL_CATEGORIES[number];
};

export type City = {
  id: number;
  name: string;
  name_arabic?: string;
  name_kurdish?: string;
};

export interface SubCategory extends Category {
  parentId: number;
}

const token = localStorage.getItem("auth");

console.log('Token: ', token)

export const fetchCategories = () =>
  api.get<ResponseWrapper<Category[]>>("/categories", {params: {token}});

export const fetchSubCategories = (id: number) =>
  api.get<ResponseWrapper<Category[]>>(`/sub-categories/${id}`, {params: {token}});

export const fetchCities = () => api.get<ResponseWrapper<City[]>>(`/cities`, {params: {token}});
