import axios, { AxiosRequestConfig } from "axios";

export type ResponseWrapper<T> = {
  data: T;
  message?: string;
  status: string;
};

const api = axios.create({
  baseURL: process.env.API_ROOT,
  headers: {
    "access-control-allow-origin": "*",
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const updateRequestConfig = (
  callback: (config: AxiosRequestConfig<any>) => void
) => {
  api.interceptors.request.use(callback);
};

export const addBearerToken = (token: string) => {
  updateRequestConfig(async (config) => {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

export const removeBearerToken = () => {
  updateRequestConfig(async (config) => {
    delete config.headers.Authorization;
    return config;
  });
};

export default api;
