import React from "react";
import { useMediaQuery as _useMediaQuery } from "@react-hook/media-query";
type Breakpoint =
  | "phone>"
  | "tablet>"
  | "desktop>"
  | "phone<"
  | "tablet<"
  | "desktop<";
const useMediaQuery = (breakpoint: Breakpoint) => {
  const breakpoints = {
    "phone>": "(min-width: 668px)",
    "tablet>": "(min-width: 992px)",
    "desktop>": "(min-width: 1180px)",
    "phone<": "(max-width: 668px)",
    "tablet<": "(max-width: 992px)",
    "desktop<": "(max-width: 1180px)",
  };
  const inMediaQuery = _useMediaQuery(breakpoints[breakpoint]);
  return inMediaQuery;
};
export default useMediaQuery;
