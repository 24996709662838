import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import React, { VFC } from "react";
import { useTranslation } from "react-i18next";

export type FilterButtonProps = {
  onClick: () => void;
};

const FilterButton: VFC<FilterButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <button className="filter-button" onClick={onClick} type="button">
      <span className="filter-button__title">{t("FILTERS.HEADER")}</span>
      <FontAwesomeIcon icon={faSlidersH} />
    </button>
  );
};

export default FilterButton;
