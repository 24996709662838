import { addErrorMessage, addSuccessMessage, removeMessage } from "./actions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Snackbar {
  id: string;
  type: "error" | "ok";
  message: string;
  title: string;
}

export type SnackbarsState = {
  snackbars: Snackbar[];
};

const initialState: SnackbarsState = {
  snackbars: [],
};

export const snackbarsSlice = createSlice({
  name: "snackbars",
  initialState,
  reducers: {
    addSnackbar: (state, action: PayloadAction<Snackbar>) => {
      state.snackbars.push(action.payload);
    },
    removeCurrentSnackbar: (state) => {
      state.snackbars.shift();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        addErrorMessage.fulfilled,
        (state, action: PayloadAction<Snackbar>) => {
          state.snackbars.push(action.payload);
        }
      )
      .addCase(
        addSuccessMessage.fulfilled,
        (state, action: PayloadAction<Snackbar>) => {
          state.snackbars.push(action.payload);
        }
      )
      .addCase(
        removeMessage.fulfilled,
        (state, action: PayloadAction<Snackbar[]>) => {
          state.snackbars = action.payload;
        }
      );
  },
});

export const { addSnackbar, removeCurrentSnackbar } = snackbarsSlice.actions;
export const snackbarsReducer = snackbarsSlice.reducer;
