import React, { VFC } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const Page4404: VFC = () => {
  const { t } = useTranslation();

  return (
    <section className="start-page">
      <div className="start-page__content">
        <h1 className="start-page__title">{t("PAGE_404.TITLE")}</h1>
      </div>
    </section>
  );
};

export default Page4404;
