import React, {useEffect, useRef, useState, VFC} from "react";
import "./styles.scss";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import {resetSuccessMsg, resetUserPassword, selectLang, selectResetPasswordShow} from "~/store/slices/user";
import * as Yup from "yup";
import { Field, Formik, FormikProps } from "formik";
import TextInput from "../../components/FormInputs/TextInput";
import Button from "~/components/Button";

type RecoveryPasswordType = {
    email: string;
}

const PasswordRecoveryPage: VFC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const lang = useSelector(selectLang);
  const formikRef = useRef(null);
  const dispatch = useDispatch();
  const showSuccessMsg = useSelector(selectResetPasswordShow);

  const RecoverySchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Mail is required"),
  });

  const initialValues: RecoveryPasswordType = {
    email: "",
  };

    const handleSubmit = async (values: RecoveryPasswordType) => {
        setLoading(true);
        await dispatch(resetUserPassword(values));
        formikRef?.current?.resetForm({values: initialValues})
        setLoading(false);
    };

    useEffect(() => {
        dispatch(resetSuccessMsg());
    }, []);

  return (
    <section className="start-page">
      <div className="start-page__content">
        <h1 className="start-page__title">Reset your password</h1>
        <div className="reset-password-page">
            {!showSuccessMsg ?
                <h4>Please enter the email you are registered on Qeysari with.</h4> :
                <div className="recover-message">
                    <h4>An email with a link to reset your password has just been sent to the email address registered with us.</h4>
                </div>
            }
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={RecoverySchema}
                innerRef={formikRef}
            >
                {(formik: FormikProps<RecoveryPasswordType>) => {
                return (<>
                <form className="recovery-form">
                    <Field
                        type="email"
                        name="email"
                        component={TextInput}
                        placeholder={'Enter your e-mail*'}
                    />
                    <Button
                        onClick={formik.handleSubmit}
                        type="submit"
                        className="registration-form__submit"
                        loading={loading}
                    >
                        {t("BUTTONS.RESET_PASSWORD")}
                    </Button>
                </form>
                </>)}}
            </Formik>
        </div>
      </div>
    </section>
  );
};

export default PasswordRecoveryPage;
