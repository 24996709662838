import React, { useEffect, useState } from "react";

//NOTE: use without nesting, othervise overflow will go off
const useFullscreenModal = (
  initialState: boolean
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [isOpen, setIsOpen] = useState(initialState);
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = null;
    }
  }, [isOpen]);
  return [isOpen, setIsOpen];
};
export default useFullscreenModal;
