import { OrderType } from "~/consts/data/order";
import { VehicleFuel, VehicleGearbox } from "~/consts/data/vehicles";
import api, { ResponseWrapper } from "./axios";

export interface ProductDetailsMedia {
  type: string;
  url: string;
  thumbnail_url?: string;
}

export interface Media {
  type: string;
  thumbnail_url: string;
  url?:string;
}

export interface MediaCreateModel {
  type: "youtube" | "image";
  content: string;
}

export interface Product {
  id: number;
  status: string;
  category: number;
  media: Media[];
  title: string;
  info: string;
  price: number;
  city_id: number;
  city: string;
  created: Date;
  brand?: string;
  profile_logo?: string,
  user_id:number,
  saved_ad?:boolean
}


//TODO Why there is several product types?
export interface ProductDetailsType {
  [key: string]: number | string | ProductDetailsMedia[] | boolean;
  id: number;
  user_id: number;
  status: string;
  category_id: number;
  category: string;
  sub_category_id: number;
  sub_category: string;
  title: string;
  youtube: string;
  price: number;
  description: string;
  name_lastname: string;
  phone: string;
  city_id: number;
  city: string;
  media: ProductDetailsMedia[];
  ad_views: number;
  saved_ad: boolean;
  extendable: boolean;
  more_ads: any;
}
export interface VehicleProductDetails extends ProductDetailsType {
  vehicle_brand: number;
  vehicle_model: number;
  vehicle_year: number;
  vehicle_milage: number;
  vehicle_fuel: string;
  vehicle_gearbox: string;
}

export interface AccommodationProductDetails extends ProductDetailsType {
  accommodation_square_meter: number;
  accommodation_bedrooms: number;
  accommodation_sale_rent: string;
  accommodation_type: string;
}

export type ElectronicsProductDetails = ProductDetailsType;

export type OtherProductDetails = ProductDetailsType;

export interface ProductList {
  status: string;
  ads: Product[];
  ads_count: number;
}

export interface SavedProductList {
  ads: Product[];
  ads_count: number;
  status: "ok" | "error";
  message: string;
  system_message: string;
}

export type ProductRequestParams = {
  category?: string;
  city?: string;
  sub_category?: string;
  price_min?: string;
  price_max?: string;
  vehicle_brand?: string;
  vehicle_model?: string;
  vehicle_milage_min?: number;
  vehicle_milage_max?: number;
  vehicle_year_min?: number;
  vehicle_year_max?: number;
  vehicle_gearbox?: VehicleFuel;
  vehicle_fuel?: VehicleGearbox;
  accommodation_square_meter_min?: number;
  accommodation_square_meter_max?: number;
  accommodation_bedrooms_min?: number;
  accommodation_bedrooms_max?: number;
  accommodation_sale_rent?: string;
  accommodation_type?: string;
  search?: string;
  order?: OrderType;
};

export interface ProductCreateModel {
  token?: string;
  category: number;
  sub_category: number;
  youtube: string;
  title: string;
  price: number;
  description: string;
  name_lastname: string;
  phone: string;
  city: number;
  media: MediaCreateModel[];
}

export interface VehicleProductCreateModel extends ProductCreateModel {
  vehicle_brand: number;
  vehicle_model: number;
  vehicle_year: number;
  vehicle_milage: number;
  vehicle_fuel: string;
  vehicle_gearbox: string;
}
export interface AccommodationProductCreateModel extends ProductCreateModel {
  accommodation_square_meter: number;
  accommodation_bedrooms: number;
  accommodation_sale_rent: string;
  accommodation_type: string;
}
export interface ProductCreateResponse {
  status: "ok" | "error";
  message: string;
  system_message: string;
}
export interface SavedParams {
  ad_id: number;
  token: string;
}
export interface SavedFilterParams {
  id: number;
  token: string;
}

export interface ProductGet {
  productId: number;
  token: string | null;
  increment_view?: number;
  more_ads?: number;
}

export type FilterDataFields = {
  category: string | number;
  vehicle_brand?: string | number;
  vehicle_model?: string | number;
  city?: number;
  sub_category?: string | number;
  price_min?: number;
  price_max?: number;
  vehicle_year_min?: number;
  vehicle_year_max?: number;
  vehicle_milage_min?: number;
  vehicle_milage_max?: number;
  vehicle_gearbox?: string | number;
  vehicle_fuel?: string | number;
  accommodation_square_meter_min?: string | number;
  accommodation_square_meter_max?: string | number;
  accommodation_bedrooms_min?: string | number;
  accommodation_bedrooms_max?: string | number;
  accommodation_sale_rent?: string;
  accommodation_type?: string;
};

export type SavedProductsOrderParams = {
  token?: string | null;
  order?: OrderType;
}

export type SavedFilterssOrderParams = {
  token?: string | null;
  order?: OrderType;
}

const token = localStorage.getItem("auth");

export const fetchLatestProducts = () => api.get<ProductList>(`/ad/list`, {params: {token}});

export const fetchProductsWithParams = (params: ProductRequestParams) =>
  api.get<ProductList>("/ad/list", { params: {...params, token} });

export const fetchSavedProductsOrder = (params: SavedProductsOrderParams ) =>
  api.get<SavedProductList>(`/ad/favorites/list/`, { params: {...params, token} });

export const fetchProductDetails = (
  productId: number,
  token: string | null,
  increment_view = 1,
  more_ads = 1
) =>
  api.get<ResponseWrapper<ProductDetailsType>>(
    `/ad/${productId}?token=${token}&more_ads=${more_ads}&increment_view=${increment_view}`
  );

export const createAd = (product: ProductCreateModel) =>
  api.post<ProductCreateResponse>("/ad/create", product);

export const fetchSaved = (token: string | null) =>
  api.get(`/ad/favorites/list?token=${token}`, {});

export const fetchSavedAdd = (params: SavedParams) =>
  api.post<ProductCreateResponse>(`/ad/favorites/save`, { ...params });

export const fetchSavedDelete = (params: SavedParams) =>
  api.post<ProductCreateResponse>(`/ad/favorites/delete`, { ...params });

export const fetchFiltersSaved = (token: string | null, filters: FilterDataFields) => {
    const json = JSON.stringify({ token: token, filter: filters });
    return api.post<ProductCreateResponse>(`/ad/filters/save`, json);
};

export const fetchFiltersList = (token: string | null) =>
  api.get(`ad/filters/list?token=${token}`);

export const fetchFiltersListOrder = (params: SavedFilterssOrderParams) =>
  api.get(`ad/filters/list`, { params: {...params, token} });

export const fetchFilterSavedDelete = (params: SavedFilterParams) =>
  api.post<ProductCreateResponse>(`/ad/filters/delete`, { ...params });

export const fetchElectronicsBrands = (subcategory_id: number) =>
    api.get(`/electronics-brands/${subcategory_id}`, {params: {token}});
