import React, { useEffect, useState, VFC } from "react";
import LoadingDots from "~/components/LoadingDots";
import OrderDropdown from "~/components/OrderSelect";
import { OrderType } from "~/consts/data/order";
import Colors from "~/consts/Colors";
import NoResultPage from "../NoResultPage";
import Products from "./Products";
import { chunk } from "lodash";

import { Product } from "~/api/product";
import { useTranslation } from "react-i18next";
import Pagination from "~/components/Pagination";
import renderCategory from "~/helpers/renderCategory";

export type ListProps = {
  isLoading: boolean;
  products: Product[];
  productPages: Product[];
  order: OrderType;
  setOrder: (c: OrderType) => void;
  currentCategory: any;
};

const List: VFC = ({
  isLoading,
  products,
  order,
  setOrder,
  currentCategory,
}: ListProps) => {
  const pageSize = 20;
  const { t } = useTranslation();
  const [selectedPage, setSelectedPage] = useState(0);
  const productPages = chunk(products, pageSize);

  return (
    <>
      {isLoading ? (
        <LoadingDots />
      ) : products?.length > 0 ? (
        <div>
          <div className="list-page__controls">
            <span style={{ color: Colors.DARK_GREY, fontWeight: "bold" }}>
              {t("FILTERS.ShowingCount", { adsCount: products?.length })}
            </span>
            <OrderDropdown
              onSelect={(order) => {
                setOrder(order);
              }}
              value={order}
            />
          </div>

          <div className="list-page__list">
            <Products
              products={productPages[selectedPage]}
              category={currentCategory}
            />
            <Pagination
              className="list-page__pagination"
              setPage={setSelectedPage}
              selectedPage={selectedPage}
              color={renderCategory(currentCategory.slug).categoryColor}
              pageCount={productPages?.length}
              itemsOnCurrentPage={productPages[selectedPage]?.length || 0}
              itemCount={products?.length}
              pageSize={pageSize}
            />
          </div>
        </div>
      ) : (
        <NoResultPage specialMark={true} />
      )}
    </>
  );
};

export default List;
