import { VehicleBrand, VehicleModel } from "./../../../api/vehicles";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchVehicleBrands,
  fetchVehicleModels,
  fetchAllVehicleModels,
} from "./actions";

export type VehiclesState = {
  brands: VehicleBrand[];
  models: VehicleModel[];
};

const initialState: VehiclesState = {
  brands: [],
  models: [],
};

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    setBrands: (state, action: PayloadAction<VehicleBrand[]>) => {
      state.brands = action.payload;
    },
    setModels: (state, action: PayloadAction<VehicleModel[]>) => {
      state.models = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVehicleBrands.fulfilled, (state, action) => {
        state.brands = action.payload;
      })
      .addCase(fetchVehicleModels.fulfilled, (state, action) => {
        state.models = action.payload;
      })
      .addCase(fetchAllVehicleModels.fulfilled, (state, action) => {
        state.models = action.payload;
      });
  },
});

export const { setBrands, setModels } = vehiclesSlice.actions;
export const vehiclesReducer = vehiclesSlice.reducer;
