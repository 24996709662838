import React, { FC } from "react";
import Footer from "~/components/Footer";
import Header from "~/components/Header";
import Snackbar from "../Snackbar";

const SharedLayout: FC<{isProd: boolean; isHome?: boolean}> = ({ isProd = false, isHome = false, children }) => {
  return (
    <>
        <Header />
            {children}
        <Footer />
        <Snackbar />
    </>
  );
};

export default SharedLayout;
