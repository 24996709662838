import React, { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "~/store";
import { selectUserProfileSetting, selectUserToken, updateUserSettings } from "~/store/slices/user";
import "./styles.scss";
import ProfileSettingsForm, { ProfileSettingsModel } from "./ProfileSettingsForm";
import { useSelector } from "react-redux";
import { addErrorMessage, addSuccessMessage } from "~/store/slices/snackbars/actions";

export type ProfileInfoProps = {
    onClick: (editable: boolean) => void;
};

const ProfileSettings: VFC<ProfileInfoProps> = ({onClick}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userSetting = useSelector(selectUserProfileSetting)
    const token = useSelector(selectUserToken)

    const [loading, setLoading] = useState(false);
    const handleUpdateProfile = (values: ProfileSettingsModel) => {
      setLoading(true);
      const body: ProfileSettingsModel = { new_password: values.new_password, password: values.password }
      dispatch(updateUserSettings({body, token: token}))
        .unwrap()
        .then((response) => {
          if (response.status === "ok") {
            dispatch(addSuccessMessage({title: t("PROFILE.TITLE"), message: t("PROFILE.SUCCESS_MESSAGE")}))
          } else {
            dispatch(addErrorMessage({title: t("PROFILE.TITLE"), message: t("PROFILE.ERROR_MESSAGE")}))
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <main>
        <div>
            <div className="profile-setting__form">
                <ProfileSettingsForm
                    onSubmit={handleUpdateProfile}
                    type="private"
                    loading={loading}
                    initialValues={userSetting}
                    onClick={()=> onClick(true)}
                />
            </div>
        </div>
      </main>
    );
  };
  export default ProfileSettings;