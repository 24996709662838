import { City } from "./../../../api/categories";
import { Category, SubCategory } from "~/api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchAllSubCategories, fetchCategories, fetchCities, fetchSubCategories } from "./actions";

export interface CategoriesState {
  categories: Category[];
  subCategories: SubCategory[];
  cities: City[];
}

const initialState: CategoriesState = {
  categories: [],
  subCategories: [],
  cities: [],
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
    setSubCategories: (state, action: PayloadAction<SubCategory[]>) => {
      state.categories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(fetchSubCategories.fulfilled, (state, action) => {
        state.subCategories = action.payload;
      })
      .addCase(fetchAllSubCategories.fulfilled, (state, action) => {
        state.subCategories = action.payload;
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.cities = action.payload;
      });
  },
});

export const { setCategories, setSubCategories } = categoriesSlice.actions;
export const categoriesReducer = categoriesSlice.reducer;
