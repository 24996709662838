import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";
import SharedLayout from "~/components/SharedLayout";
import { getCurrentCategory } from "~/helpers";
// import useInitialRequest from "~/hooks/useInitialRequest";
import AboutPage from "~/pages/AboutPage";
import CookiesPage from "~/pages/CookiesPage";
import FaqPage from "~/pages/FaqPage";
import HowAppWorksPage from "~/pages/HowAppWorksPage";
import PrivacyPage from "~/pages/PrivacyPage";
import SavedProductsPage from "~/pages/SavedProductsPage";
import TermsOfUsePage from "~/pages/TermsOfUsePage";
import {
  fetchUserProfile, selectLang,
  // selectUserProfile,
  selectUserToken,
  useAuthenticated,
} from "~/store/slices/user";
import CreateAdPage from "../pages/CreateAdPage";
import ListPage from "../pages/ListPage";
import LoginPage from "../pages/LoginPage";
import NoResultPage from "../pages/NoResultPage";
import ProductDetailsPage from "../pages/ProductDetailsPage";
import ProfilePage from "../pages/ProfilePage";
import RegistrationPage from "../pages/RegistrationPage";
import ScrollToTop from "../pages/ScrollToTop";
import Startpage from "../pages/Startpage";
import { ThankYouPage } from "../pages/ThankYouPage";
import { routes } from "./routes";
import SellerProfilePage from "~/pages/ProfilePage/seller";
import Page4404 from "~/pages/404";
import RecoveryPage from "~/pages/ForgotPassword";
import ResetPassword from "~/pages/ForgotPassword/resetPassword";
import UnderConstruction from "~/pages/underConstruction";
export type Path = {
  path: string;
  breadcrumb: string;
  component: ReactNode;
};
// const lang = localStorage.getItem("lang");
//const CategoryBreadcrumb = ({ match }) => getCurrentCategory(match);

interface RouteInterface extends RouteProps {
  public?: boolean;
  breadcrumb?: string;
  isProd?: boolean;
}

const routing: (
  | RouteInterface
  | ((isAuthenticated: boolean) => RouteInterface)
)[] = [
  {
    path: `/:lang/`,
    exact: true,
    public: true,
    component: Startpage,
    breadcrumb: "Home",
  },
  {
    path: `/${routes.productPage}`,
    exact: true,
    public: true,
    component: ProductDetailsPage,
  },
  {
    path: `/${routes.profilePage}`,
    exact: true,
    public: false,
    component: ProfilePage,
    breadcrumb: "Profile",
  },
  {
    path: `/${routes.sellerProfile}`,
    exact: true,
    public: false,
    component: SellerProfilePage,
    breadcrumb: "Profile",
  },
  {
    path: `/${routes.listPage}`,
    exact: true,
    public: true,
    component: ListPage,
    breadcrumb: "CategoryBreadcrumb",
  },
  {
    path: `/${routes.noResultPage}`,
    exact: true,
    public: true,
    component: NoResultPage,
  },
  {
    path: `/${routes.thankYouPage}`,
    exact: true,
    public: false,
    component: ThankYouPage,
  },
  {
    path: `/${routes.createAd}`,
    exact: true,
    public: false,
    component: CreateAdPage,
    breadcrumb: "Create ad",
  },
  {
    path: `/${routes.savedProductPage}`,
    exact: true,
    public: false,
    component: SavedProductsPage,
    breadcrumb: "Saved",
  },

  //Public
  {
    path: `/${routes.register}`,
    exact: true,
    component: RegistrationPage,
    breadcrumb: "Registration",
  },

  {
    path: `/${routes.login}`,
    exact: true,
    component: LoginPage,
    breadcrumb: "Login",
  },

  {
    path: `/${routes.faqPage}`,
    exact: true,
    component: FaqPage,
    breadcrumb: "FAQ",
  },

  {
    path: `/${routes.aboutPage}`,
    exact: true,
    component: AboutPage,
    breadcrumb: "About us",
  },

  {
    path: `/${routes.termsofusePage}`,
    exact: true,
    component: TermsOfUsePage,
    breadcrumb: "Terms of use",
  },

  {
    path: `/${routes.privacyPage}`,
    exact: true,
    component: PrivacyPage,
    breadcrumb: "Privacy policy",
  },

  {
    path: `/${routes.cookiesPage}`,
    exact: true,
    component: CookiesPage,
    breadcrumb: "Cookies",
  },

  {
    path: `/${routes.howAppWorksPage}`,
    exact: true,
    component: HowAppWorksPage,
    breadcrumb: "How app works",
  },
  {
    path: `/${routes.passwordRecovery}`,
    exact: true,
    public: true,
    component: RecoveryPage,
    breadcrumb: "Recovery page",
  },
  {
    path: `/${routes.passwordReset}`,
    exact: true,
    public: true,
    component: ResetPassword,
    breadcrumb: "Reset password page",
  },
  {
    path: `*`,
    exact: true,
    public: true,
    component: Page4404,
    breadcrumb: "404",
  },
  // (isAuthenticated) =>
  //   isAuthenticated
  //     ? {
  //         path: "/",
  //         exact: true,
  //         public: false,
  //         component: Startpage,
  //         breadcrumb: "Home",
  //       }
  //     : {
  //         path: "/",
  //         exact: true,
  //         component: RegistrationPage,
  //       },
];

const Routes = () => {
  // const isLoading = useInitialRequest();
  const isLoading = false;
  const token = useSelector(selectUserToken);
  const isAuthenticated = useSelector(useAuthenticated)
  const dispatch = useDispatch();
  // const loading = useGlobalLoading();
  const lang = useSelector(selectLang);
  const isProduction = window.location.href !== 'https://flycompany.awave.host/' ? false : true;
  // const isHome = window.location.pathname === `/${lang}`;

  // const error = useGlobalError();


  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUserProfile(token));
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return <>loading</>;
  }

  const getRoutes = () => {
    const handleRoute = (
      { public: publicRoute = true, ...rest }: RouteInterface,
      index: number
    ) => {
      if (!publicRoute && !isAuthenticated) {
        return (
          <Route
            key={index}
            {...rest}
            component={() => <Redirect to={`/${lang}/register`} />}
          />
        );
      }
      return <Route key={index} {...rest} />;
    };
    return routing.map((route, index) => {
      if (typeof route === "function") {
        return handleRoute(route(isAuthenticated), index);
      } else {
        return handleRoute(route, index);
      }
    });
  };

  // if (isAuthenticated) {
  //   // if (error)
  //   //   return (
  //   //     <Router>
  //   //       <NotFoundPage />
  //   //     </Router>
  //   //   );
  //   // if (loading)
  //   //   return (
  //   //     <Router>
  //   //       <LoadingPage />
  //   //     </Router>
  //   //   );
  // }
  // const userRedirect = (nextState, replace) => {
  //   console.log('Redirect')
  //   const defaultLanguage = 'en';
  //   const redirectPath = defaultLanguage + nextState.location.pathname
  //   replace({
  //     pathname: redirectPath,
  //   })
  // };

  return (
    <BrowserRouter>
      <ScrollToTop>
        <SharedLayout isProd={isProduction}>
          {/* <Switch>
            {(token ? paths : unsecuredPaths).map(
              ({ path, component, breadcrumb }) => (
                <Route path={path} key={path} component={component} />
              )
            )}
          </Switch> */}
          <Switch>
            <Route exact path="/profile">
              <Redirect to={`/${lang}/profile`} />
            </Route>
            {getRoutes()}
            {/*<Route path="*" onEnter={userRedirect} />*/}
          </Switch>
        </SharedLayout>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Routes;
