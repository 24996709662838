import { Product } from ".";
import api, { ResponseWrapper } from "./axios";

export type RegistrationModel = {
  email: string;
  password: string;
  type: "private" | "company";
  name: string;
  lastname: string;
  phone: string;
  company_name?: string;
  logo?: string;
};

export type RegisterResponse = {
  status: "ok" | "error";
  message: string;
  system_message: string;
};

export type ResetPasswordResponse = {
  status: "ok" | "error";
  message: string;
  system_message: string;
};

export type LoginModel = {
  email: string;
  password: string;
  firebase_token: string; // now no connection for firebase
};

export type LoginResponse = {
  status: "ok" | "error";
  message: string;
  system_message: string;
  user_id?: number;
  user_token?: string;
  user_type?: "private" | "company";
};

export type Profile = {
  id: number;
  type: "private" | "company";
  name: string;
  lastname: string;
  created: Date | string;
  phone: string;
  email: string;
  credits: number;
  company_name: string;
  logo?:string;
  ads: Product[];
};

export type ProfileResponse = {
  status: "ok" | "error";
  data: Profile;
  ads_count: number;
  ads: Product[];
};

export type ProfileSettingsModel = {
  password?: string;
  passwordRepeat?: string;
  new_password: string;
  newPasswordRepeat?: string;
  type?: "private" | "company";
};

export type ProfileInfo = {
  name: string;
  lastname: string;
  company_name: string;
  phone: string;
  logo: string;
  update_logo: boolean;
};

export type LoginWithRequestData = {
  token: string;
  firebase_token: string;
  email: string;
  name: string;
  lastname: string;
};

export const fetchProfileSeller = (
  user_id: string | number,
  sub_category_id: string | number
) => api.get(`/profile/${ user_id }/${ sub_category_id }`, {});

export const registerUser = (user: RegistrationModel) =>
  api.post<RegisterResponse>(`/register`, user);

export const loginUser = (credentials: LoginModel) =>
  api.post<LoginResponse>(`/login`, credentials);

export const fetchUserProfile = (token: string) =>
  api.get<ProfileResponse>("/profile", {params: {token}});

export const fetchProfileSettingsUpdate = (
  data: ProfileSettingsModel,
  token: string | null
) =>
  api.post<ProfileSettingsModel>("/profile/settings/update", {
    ...data,
    token,
  });

export const fetchProfileUpdate = (data: ProfileInfo, token: string | null) =>
  api.post<ProfileInfo>("/profile/update", {...data, token});

export const logoutUser = (token: string | null) =>
  api.post<ProfileResponse>("/logout", {token});

export const deleteUser = (token: string | null) =>
  api.post<ProfileResponse>("/profile/delete", {token});

export const loginWith = (
  social: "apple" | "facebook",
  data: LoginWithRequestData
) => api.post<LoginResponse>(`/login/${ social }`, data);

export const fetchSellerProfileApi = (token: string, profileId: number, categoryId: number | undefined) => {
  let requestUrl = `/profile/${ profileId }`;
  if (categoryId) {
    requestUrl = `/profile/${ profileId }/${ categoryId }`;
  }
  return api.get<ProfileResponse>(requestUrl, {params: {token}});
}


export const resetPassword = (email: string) =>
  api.post<ResetPasswordResponse>("/profile/password/reset", {email});

export const changePassword = ({password, hash}: { password: string; hash: string; }) =>
  api.post<ResetPasswordResponse>(`/profile/password/reset/${ hash }`, {password, confirmed: password});
