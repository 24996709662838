import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Category, City, SubCategory } from "~/api";
import {
  selectCities,
  selectSubCategoriesByParent,
} from "~/store/slices/categories";
import FilterForm, { numberField, radioField } from "./FilterForm";
import Button from "~/components/Button";
import Colors from "~/consts/Colors";
import { ClipLoader } from "react-spinners";

export type ElectronicsFilterFields = {
  city?: City;
  sub_category?: SubCategory;
  price_min?: number;
  price_max?: number;
  category_id: string;
};

export type ElectronicsFilterProps = {
  category: Category;
  filterValues?: ElectronicsFilterFields;
  setFilterValues: React.Dispatch<React.SetStateAction<any>>;
  onSubmit: (values: ElectronicsFilterFields) => void;
  loading: boolean;
};

const VehicleFilter: FC<ElectronicsFilterProps> = ({
  category,
  filterValues,
  setFilterValues,
  onSubmit,
  loading,
}) => {
  const { t } = useTranslation();
  const [_filterValues, _setFilterValues] = useState(filterValues);
  const cities = useSelector(selectCities);
  const subCategories = useSelector(selectSubCategoriesByParent(category.id));

  const vehicleFilters = [
    radioField("city", t("FILTERS.city"), cities),
    numberField("price_min", t("FILTERS.price_min")),
    numberField("price_max", t("FILTERS.price_max")),
    radioField("sub_category", t("FILTERS.sub_category"), subCategories),
  ];
  let initialValues = {};
  vehicleFilters.map((filter) => {
    initialValues = {
      ...initialValues,
      [filter.name]: "",
    };
  });

  const handleSetFilterValues = (values: any) => {
    _setFilterValues(values);
    setFilterValues(values);
  };

  const filters = vehicleFilters;
  return (
    <Formik
      initialValues={_filterValues || { ...initialValues }}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <form onSubmit={formik.handleSubmit} className="filter_form">
            <>
              <FilterForm
                formik={formik}
                setFilterValues={handleSetFilterValues}
                filters={filters}
                category={"electronics"}
              />
              <div className="filter__buttons-wrapper">
                <Button
                  type="submit"
                  styles={{ backgroundColor: Colors.DARK_SILVER }}
                  className="filter__submit-button"
                >
                  {loading ? (
                    <ClipLoader color="white" size={20} />
                  ) : (
                    t("BUTTONS.APPLY")
                  )}
                </Button>
                <Button
                  className="filter__reset-button"
                  onClick={() => {
                    handleSetFilterValues(null);
                  }}
                >
                  {t("BUTTONS.CLEAR")}
                </Button>
              </div>
            </>
          </form>
        );
      }}
    </Formik>
  );
};
export default VehicleFilter;
