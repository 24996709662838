import React, { useEffect, useState, VFC } from "react";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "~/components/Pagination";
import OrderDropdown from "~/components/OrderSelect";
import "./styles.scss";
import "../ProfilePage/styles.scss";
import Colors from "~/consts/Colors";
import { OrderType } from "~/consts/data/order";
import {
  fetchSavedFilters,
  removeSavedFilters,
  selectDataFromWatchlist,
  selectSavedFilters,
} from "~/store/slices/products";
import { chunk } from "lodash";
import { useTranslation } from "react-i18next";
import TrashSvg from "../../assets/icons/trash-icon.svg";
import Watchlist from "~/components/Watchlist";
import List from "../ListPage/List";
import { fetchFilterSavedDelete, fetchFiltersListOrder, fetchProductsWithParams } from "~/api";
import { getCategoryNameById, getCurrentCategory } from "~/helpers";
import { selectCategories } from "~/store/slices/categories";
import { addErrorMessage } from "~/store/slices/snackbars";
import { selectUserToken } from "~/store/slices/user";
import { useDevice } from "~/hooks/useDevice";

const WatchlistTab: VFC = () => {
  const { t } = useTranslation();
  const pageSize = 4;
  const savedFilters = useSelector(selectSavedFilters);
  const [order, setOrder] = useState<OrderType>("created_desc");
  const [selectedPage, setSelectedPage] = useState(0);
  const [resultList, setListResult] = useState<boolean>(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [categoryList, setCategoryList] = useState([]);

  const filterData = useSelector(selectDataFromWatchlist);
  const [products, setProducts] = useState([]);
  const isLoading = !Object.keys(filterData).length || isFetchingData;
  const dispatch = useDispatch();
  const token = useSelector(selectUserToken);
  const device: string = useDevice();

  useEffect(() => {
    if (Object.keys(filterData).length) {
      getListProduct();
    }
  }, [filterData]);

  const handleDelete = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ): Promise<void> => {
    e.preventDefault();
    try {
      const { data } = await fetchFilterSavedDelete({ id: id, token });
      if (data.status === "ok") {
        dispatch(removeSavedFilters(id));
        setListResult(false);
      }
    } catch (e) {
      console.log(e?.response, "error");
    }
  };

  const getListProduct = async () => {
    setIsFetchingData(true);

    try {
      const { data } = await fetchProductsWithParams({
        category: filterData.filters?.category,
        city: filterData.filters?.city,
        sub_category: filterData.filters?.sub_category,
        price_min: filterData.filters?.price_min,
        price_max: filterData.filters?.price_max,
        vehicle_brand: filterData.filters?.vehicle_brand,
        vehicle_model: filterData.filters?.vehicle_model,
        vehicle_milage_min: filterData.filters?.vehicle_milage_min,
        vehicle_milage_max: filterData.filters?.vehicle_milage_max,
        vehicle_year_min: filterData.filters?.vehicle_year_min,
        vehicle_year_max: filterData.filters?.vehicle_year_max,
        vehicle_fuel: filterData.filters?.vehicle_fuel,
        vehicle_gearbox: filterData.filters?.vehicle_gearbox,
        accommodation_square_meter_min:
          filterData.filters?.accommodation_square_meter_min,
        accommodation_square_meter_max:
          filterData.filters?.accommodation_square_meter_max,
        accommodation_bedrooms_min:
          filterData.filters?.accommodation_bedrooms_min,
        accommodation_bedrooms_max:
          filterData.filters?.accommodation_bedrooms_max,
        accommodation_sale_rent: filterData.filters?.accommodation_sale_rent,
        accommodation_type: filterData.filters?.accommodation_type,
        // searchValue,
        order,
      });

      if (data.status === "ok") {
        setProducts(data.ads);
        setIsFetchingData(false);
      } else {
        addErrorMessage({
          message: "Failed",
          title: data.message,
        });
      }
    } catch (e) {
      console.log(e?.response, "error");
    }
  };

  const watchlistProductOrder = async () => {
    const token = localStorage.getItem("auth");
    try {
      const { data } = await fetchFiltersListOrder({token, order});
      if (data?.status === "ok") {
        data?.filters.map((filter) => {
          const tempArray = categoryList;
          tempArray.push(filter?.filter?.category);
          setCategoryList(tempArray);
        });

        dispatch(fetchSavedFilters(data.filters));
      }
    } catch (e) {
      console.log(e.response, "error");
    }
  };

  useEffect(() => {
    watchlistProductOrder()
  }, [order])

  const watchlistPage = chunk(savedFilters, pageSize);
  const categories = useSelector(selectCategories);

  const currentCategory = getCurrentCategory(
    categories,
    getCategoryNameById(filterData?.filters?.category)
  );
  return !resultList ? (
    <>
      <div className="saved-product-page__title">
        <h1 className="saved-product-page__title__text">
          {t("SAVED.WATCHLIST")}
        </h1>
      </div>
      <div>
        <div className="list-page__controls">
          <span style={{ color: Colors.DARK_GREY, fontWeight: "bold" }}>
            {t("FILTERS.ShowingSavedFilterCount", {
              adsCount: savedFilters.length,
            })}
          </span>
          <OrderDropdown
            onSelect={(order) => {
              setOrder(order);
            }}
            value={order}
          />
        </div>
        <div className="saved-product-page__list">
          <Watchlist
            products={watchlistPage[selectedPage]}
            setListResult={setListResult}
            handleDelete={handleDelete}
            icon={<TrashSvg className="saved-product-page__trash-icon" />}
          />
          <Pagination
            className="saved-product-page__pagination"
            setPage={setSelectedPage}
            selectedPage={selectedPage}
            pageCount={watchlistPage?.length}
            itemsOnCurrentPage={watchlistPage[selectedPage]?.length || 0}
            itemCount={savedFilters?.length}
            pageSize={pageSize}
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="saved-product-page__list_title">
        <h1
          className="saved-product-page__list_title__text"
          onClick={() => setListResult(false)}
        >
          {`${t("SAVED.WATCHLIST")} >`}
        </h1>
        <p className="saved-product-page__list_title__text">
          {filterData?.title}
        </p>
        <button
          style={{
            height: device === "isMobile" || device === "isTablet" ? 20 : 40,
          }}
          onClick={(e) => handleDelete(e, filterData?.id)}
          className="product-card__watchlist-icon"
        >
          <TrashSvg className="saved-product-page__trash-icon" />
        </button>
      </div>
      <List
        isLoading={isLoading}
        products={products}
        order={order}
        setOrder={setOrder}
        currentCategory={currentCategory}
      />
    </>
  );
};

export default WatchlistTab;
