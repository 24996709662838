export default {
  //main colors
  DARK_GREY: '#463F3A',
  GREY: '#8A817C',
  LIGHT_GREY: '#BCB8B1',
  CREAMY: '#F4F3EE',
  PINK: '#CE4257',
  WHITE_BG: '#FAFAFA',
  PURPLE: '#03045E',
  BLUE: '#023E8A',
  LIGHT_BLUE: '#00B4D8',
  GREEN: '#17B6A3',
  YELLOW: '#f5ec44',
  GREEN_BLUE: '#00FFA3',
  LIGHT_PURPLE: '#7B65BB',
  DARK_SILVER: '#353535',
  SILVER: '#46494C',
  LIGHT_SILVER: '#C5C3C6',
  BROWN: '#4F000B',
  BROWN_RED: '#720026',
  DARK_RED: '#6e0211',
  RED: '#C73E1D',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  SHADOW: '#333333',

  //icon
  ICON_COG: '#686764',

  //social
  FACEBOOK: '#475993',

  //categories
  BORDER_VEHICLE: 'rgba(0, 180, 216, 0.3)',
  BORDER_OTHER: 'rgba(255, 127, 81, 0.3)',
  BORDER_ACCOMODATION_GREEN: "rgba(0, 255, 163, 0.3)", 
  BORDER_ACCOMODATION_PURPLE: "rgba(123, 101, 187, 0.3)",
  HEADER_ACCOMODATION_GREEN: "#31C3AD",

  //input colors
  INPUT_BORDER: 'rgba(0, 0, 0, 0.2)',
  INPUT_PLACEHOLDER: 'rgba(0, 0, 0, 0.5)',
  ERROR_INPUT_BORDER: 'rgba(206, 66, 87, 0.54)'
};
