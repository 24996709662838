import React, { FunctionComponent, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Button from "~/components/Button";
import { useHistory } from "react-router-dom";
import Logo from "~/assets/icons/thankyoupage-icon.svg";

import "./styles.scss";
import {useSelector} from "react-redux";
import {selectLang} from "~/store/slices/user";

export const ThankYouPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const lang = useSelector(selectLang);

  return (
    <div className="thankPage">
      <div className="iconGood">
        <Logo className="thankPage__logo" />
      </div>

      <div className="thankPage__title">
        <h2>{t("CREATE_AD.THANK_YOU_HEADER")}</h2>
      </div>

      <div className="thankPage__text">
        <p>{t("CREATE_AD.THANK_YOU_MESSAGE")}</p>
      </div>
      <div className="goBack-btn">
        <Button
          className="thankPage__button"
          onClick={() => {
            history.push(`/${lang}/`);
          }}
        >
          {t("CREATE_AD.RETURN")}
        </Button>
      </div>
    </div>
  );
};
