import React, { VFC } from "react";
import Breadcrumbs from "~/components/Breadcrumbs";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import Accordion from "./Accordion";
import useNotEnglish from "~/hooks/useNotEnglish";

const FaqPage: VFC = () => {
  const { t, i18n } = useTranslation();
  const notEnglish = useNotEnglish();

  const faqs = [
    {
      question: t("PAGES.FAQ.TITLE_1"),
      answer: t("PAGES.FAQ.TEXT_1"),
    },
    {
      question: t("PAGES.FAQ.TITLE_2"),
      answer: t("PAGES.FAQ.TEXT_2"),
    },
    {
      question: t("PAGES.FAQ.TITLE_3"),
      answer: t("PAGES.FAQ.TEXT_3"),
    },
    {
      question: t("PAGES.FAQ.TITLE_4"),
      answer: t("PAGES.FAQ.TEXT_4"),
    },
    {
      question: t("PAGES.FAQ.TITLE_5"),
      answer: t("PAGES.FAQ.TEXT_5"),
    },
    {
      question: t("PAGES.FAQ.TITLE_6"),
      answer: t("PAGES.FAQ.TEXT_6"),
    },
    {
      question: t("PAGES.FAQ.TITLE_7"),
      answer: t("PAGES.FAQ.TEXT_7"),
    },
  ];

  return (
    <main className={`faq-page ${notEnglish && 'east'}`}>
      <Breadcrumbs className="faq-page__breadcrumbs" />

      <div className="faq-page__title">
        <h1 className="faq-page__text">{t("FOOTER.FAQ")}</h1>
      </div>

      <div>
        {faqs.map((faq, index) => {
          if(i18n.language === "arab" && index >= 4) {
            return false;
          }
          return (
              <Accordion key={index} {...faq}/>
          );
        })}
      </div>

    </main>
  );
};

export default FaqPage;
