import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import useMediaQuery from "~/hooks/useMediaQuery";

export type FilterItemProps = {
  label: string;
  isOpen: boolean;
  onClick: () => void;
  value?: string;
  numberType?: boolean;
};

const FilterItem: FC<FilterItemProps> = ({
  children,
  label,
  isOpen,
  onClick,
  value,
  numberType = false,
}) => {
  const isTablet = useMediaQuery("tablet>");
  return (
    <div
      className={`${
        numberType ? "filter_block_number " : "default_filter_block"
      }`}
    >
      {!isTablet ? (
        <button
          className={`filter-item ${isOpen ? "" : "filter-item--closed"}`}
          onClick={onClick}
          type="button"
        >
          <span className="filter-item__label">{label}</span>
          <span className="filter-item__value">{value}</span>
          <FontAwesomeIcon
            icon={faAngleRight}
            className={`filter-item__icon ${
              isOpen ? "filter-item__icon--rotated" : ""
            }`}
          />
        </button>
      ) : (
        <h3 className="filter-item__label">{label}</h3>
      )}
      <div
        className={`filter-item__content ${
          isTablet || isOpen ? "" : "filter-item__content--closed"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default FilterItem;
