import React, { VFC } from "react";
import Breadcrumbs from "~/components/Breadcrumbs";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import useNotEnglish from "~/hooks/useNotEnglish";

const CookiesPage: VFC = () => {
  const { t } = useTranslation();
  const notEnglish = useNotEnglish();

  return (
    <main className={`cookies-page ${notEnglish && 'east'}`}>
      <Breadcrumbs className="cookies-page__breadcrumbs" />

      <div className="cookies-page__title">
        <h1 className="cookies-page__text">{t("FOOTER.LINKS.COOKIES")}</h1>
      </div>

      <div className="cookies-page__content" dangerouslySetInnerHTML={{__html: t("PAGES.COOKIES.TEXT")}} />
    </main>
  );
};

export default CookiesPage;
