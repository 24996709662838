import React, { FC } from "react";
import ProductDetailsPage from "~/pages/ProductDetailsPage";
import FullscreenModalHeader from "./FullscreenModalHeader";
import "./styles.scss";
export type FullscreenModalProps = {
  isOpen: boolean;
  onClose: (x: boolean) => void;

};

const FullscreenModal: FC<FullscreenModalProps> = ({
  children,
  isOpen,
  onClose,
}) => {
  return (
    <div
      className={`fullscreen-modal ${isOpen ? "" : "fullscreen-modal--closed"}`}
    >
      <FullscreenModalHeader />
      <div className="fullscreen-modal__content">{children}</div>
    </div>
  );
};

export default FullscreenModal;
