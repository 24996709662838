import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import "./src/i18n/config";
import packageInfo from "./package.json";
import App from "./src/App";

console.log(`${packageInfo.description} --- ${packageInfo.version}`);

ReactDOM.render(<App />, document.getElementById("root"));
module.hot.accept();
