import React, {useState, ChangeEventHandler, useEffect} from "react";
import SearchIcon from "../../assets/icons/search.svg";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {fetchProductsWithParams} from "~/api";
import {addErrorMessage} from "~/store/slices/snackbars";
import {useSelector} from "react-redux";
import {selectLang} from "~/store/slices/user";

interface Search {
  selectCounty?: boolean;
}
const SearchBar = ({ selectCounty = true }: Search) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get("search");

  const [selectedOption, setSelectedOption] = useState("");
  const [searchVal, setSearchValue] = useState("");
  const lang = useSelector(selectLang);

  const dropDownOptions = [
    t("common.Sweden"),
    t("common.England"),
    t("common.Middle_East"),
  ];

  const handleChange = (e: ChangeEventHandler<HTMLSelectElement>) => {
    setSelectedOption(e.target.value);
  };

  const handleSearch = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    try {
      const { data } = await fetchProductsWithParams({
        search: searchVal,
      });

      if (data.status === "ok") {
        history.push(`/${lang}/products/all?search=${searchVal}`, [data.ads]);
      } else {
        addErrorMessage({
          message: "Failed",
          title: 'Failed to fetch search data',
        });
      }
    } catch (e) {
      console.log(e?.response, "error");
    }
  };

  const onQueryChange = (value: string) => {
    setSearchValue(value);
    const params = new URLSearchParams({search: value });
    history.replace({ pathname: location.pathname, search: params.toString() });
  }

  return (
      <form onSubmit={handleSearch}>
        <label htmlFor="search" className="search">
          <input
              id="search"
              placeholder={t("PLACEHOLDERS.SEARCH")}
              className="search__input"
              onChange={(e) => onQueryChange(e.target.value)}
              value={param !== null && param || ''}
          />
          {selectCounty && (
              <>
                <select
                    onChange={handleChange}
                    value={selectedOption}
                    className="search__dropdown"
                >
                  {dropDownOptions.map((option, index) => (
                      <option key={index}>{option}</option>
                  ))}
                </select>
              </>
          )}
          <button type="submit">
            <SearchIcon className="search__icon" />
          </button>
        </label>
      </form>
  );
};
export default SearchBar;
